
.invitations {

	padding: 0;
	list-style: none;
	clear: both;
}

.invitation {

	position: relative;
	padding: 20px 0;
	border-bottom: 1px solid $primaryDark;

	.email {

		padding-bottom: 10px;
		color: $primaryDark;
		font-size: 1em;
		font-family: $quicksand;
		font-weight: 400;
	}


	.link {

		color: $primaryColored;
		font-size: .750em;
		font-family: $quicksand;
		font-weight: 700;
		text-transform: uppercase;
	}

	.revoke {
		
		position: absolute;
		top: 40px;
		right: 0;
		color: $errorColor;
		font-size: 1.1em;
		text-decoration: none;

		@media screen and (max-width: $tinyBreakpoint) {

			top: 48px;
		}

		&.right {

			right: 20px;
			top: 45px;
		}

		&:before {

			content: "\e903";
			font-family: BeanIcons;
		}
	}
}