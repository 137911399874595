

.title-1 {

	margin: 0 0 20px 0;
	padding: 0;
	color: $primaryDark;
	font-size: 32px;
	font-family: $quicksand;
	line-height: 1.4em;
	font-weight: 400;


	&.light,
	.light {

		color: $primaryLight;
	}

	@media screen and (max-width: $tinyBreakpoint) {

		font-size: 1.8em;
	}
}




.title-2 {

	margin: 0 0 30px 0;
	padding: 0;
	color: $primaryDark;
	font-size: 22px;
	font-family: $quicksand;
	line-height: 1.4em;
	font-weight: 400;


	&.light,
	.light {

		color: $primaryLight;
	}
}




.title-3 {

	margin: 0 0 20px 0;
	padding: 0;
	color: $primaryDark;
	font-size: 18px;
	font-family: $quicksand;
	line-height: 1.4em;
	font-weight: 400;


	&.light,
	.light {

		color: $primaryLight;
	}


	&.green {

		color: $primaryColored;
	}
}




.reading-text {

	margin: 15px 0 5px;
	color: $primaryDark;
	font-size: 16px;
	font-family: $oxygen;
	line-height: 1.6em;
	font-weight: 400;


	&.light,
	.light {

		color: $primaryLight;
	}

	&.error {

		color: $errorColor;
	}


	a {

		color: $primaryColored;
	}

	&.f-14 {

		font-size: 14px;
	}
}


.strong {

	font-weight: 700;
}

.uppercase {

	text-transform: uppercase;
}

.centered {

	text-align: center;
}

a {

	text-decoration: none;

	&.green {

		color: $primaryColored;
	}

	&.confirm {

		.confirm-text {

			display: none;
		}

		&.confirmed {

			.initial-text {

				display: none;
			}

			.confirm-text {

				display: block;
			}
		}
	}
}

sup {
	line-height: 5px;
	font-size: 12px;
	margin-left: 2px;
}

.span-error {

	color: $errorColor;
}

.icon {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: auto;
}

h2.tags.centered {

	margin: 0;
}