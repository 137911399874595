
.avatar {

	width: 120px; 
	height: 120px; 
	margin: 40px auto;
	background-position: center top;
	background-size: cover;
	cursor: pointer;
	border-radius: 50%;


	&.small {

		width: 35px; 
		height: 35px; 
		margin: 5px 10px;
		padding-right: 15px;

		&:after {
		
			content: "\e900";
			font: normal normal normal 12px/1 BeanIcons;
			position: absolute;
			top: 17px;
			right: 20px;
		    text-rendering: auto;
		}


		/*&:hover {*/

			/*&:after {*/
			/**/
			    /*content: "\e909";*/

			/*}*/

			/*&.open {*/

				/*&:after {*/
				/**/
				    /*content: "\e902";*/
					/*font-size: 10px;*/
				/*}*/
			/*}*/

		/*}*/


		&.open {

			&:after {

				 content: "\e902";
				 font-size: 10px;
			}
		}
	}

	img {

		width: 100%;
		height: 100%;
		border-radius: 60px;
		box-sizing: border-box;
	}
}