
.campaign-element {

	position: relative;
	margin-top: 20px;
	padding: 30px;
	border: 1px solid $thirdLight;
	border-radius: 3px;
	box-sizing: border-box;
	background-color: $secondaryLight;
	text-align: left;
	overflow: auto;

	@media screen and (max-width: $smallBreakpoint) {

		padding: 20px;
	}

	.image {

		float: left;
		position: relative;

		.picture {

			display: block;
			float: left;
			height: 175px;
			border-radius: 3px;

			&.draft {
				opacity: .5;
			}

			@media screen and (max-width: $smallBreakpoint) {
				width: 180px;
				height: auto;
			}

			@media screen and (max-width: $tinyBreakpoint) {
				width: 100%;
				height: auto;
			}
		}

		.overflow {

			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(12,12,12,0.2);
			background: -moz-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.55) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(12,12,12,0.2)), color-stop(100%, rgba(28,28,28,0.55)));
			background: -webkit-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.55) 100%);
			background: -o-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.55) 100%);
			background: -ms-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.55) 100%);
			background: linear-gradient(to bottom, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.55) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c0c0c', endColorstr='#1c1c1c', GradientType=0 );
		}

		.status {

			position: absolute;
			top: 0;
			width: 100%;
			text-align: center;
			font-family: $oxygen;

			&.draft div {

				background: $thirdDark;
				color: $primaryLight;
			 }

			 &.launched div {

				  background: $primaryColored;
				  color: $primaryLight;
			  }

			 &.pending div {

				  background: #f0ad4e;
				  color: $primaryLight;
			  }

			 &.approved div {

				  background: white;
				  color: $primaryDark;
			  }

			 &.ended div {

				  background: $primaryDark;
				  color: $primaryLight;
			  }

			 &.blocked div {

				  background: $errorColor;
				  color: $primaryLight;
			  }

			div {

				color: white;
				font-size: 12px;
				font-weight: 500;
				padding: 5px 10px;
				display: inline-block;
				border-radius: 0 0 3px 3px;
			}
		}
	}

	.data {

		width: 100%;
		display: table;
		box-sizing: border-box;
		vertical-align: top;

	    @media screen and (min-width: $smallBreakpoint) {
	        
	        width: calc(100% - 235px);
	        padding-left: 30px;
	    }

	    @media screen and (max-width: $smallBreakpoint) {

	        width: calc(100% - 180px);
	        padding-left: 20px;
	    }

	    @media screen and (max-width: $tinyBreakpoint) {

	        width: 100%;
	        padding-left: 0;
	    }


		.title-2 {

			margin: 0;
			padding: 0;
			color: $primaryDark;
			font-size: 1.4em;
			font-family: $quicksand;
			line-height: 1.4em;
			width: 430px;

			@media screen and (max-width: $smallBreakpoint) {
				width: 100%;
			}

			@media screen and (max-width: $tinyBreakpoint) {
				padding-top: 20px;
			}
		}

		.reading-text {

			margin: 0;
			padding: 5px 0 10px;

			@media screen and (max-width: $smallBreakpoint) {
				padding-bottom: 20px;
			}
		}

		
		.link {

			color: $primaryColored;
			font-weight: 700;
			text-decoration: none;
		}


		.primary {

			display: table-row;
			width: calc(100% - 350px);
			height: 120px;
			vertical-align: top;

			@media screen and (max-width: $smallBreakpoint) {

				height: auto;
			}
		}


		.secondary {

			display: table-row;
			vertical-align: bottom;
		}


		.block-info {

			float: left;
			margin-right: 35px;

			@media screen and (max-width: $smallBreakpoint) {

				margin-right: 20px;

				&.contributions {

					display: none;
				}
			}

			@media screen and (max-width: $tinyBreakpoint) {

				width: 100%;
				margin-right: 0;

				&.contributions {

					 display: block;
				}
			}
		}


		.actions {

			position: absolute;
			top: 15px;
			right: 30px;
			box-sizing: border-box;

			@media screen and (max-width: $smallBreakpoint) {

				position: static;

				.button {
					width: auto;
				}

				.button, .button-bordered {
					margin: 0 20px 0 0;
				}
			}

			@media screen and (max-width: $tinyBreakpoint) {

				.button, .button-bordered {
					width: 100%;
					text-align: center;
					margin: 15px 0 0;
				}
			}
		}
	}
}