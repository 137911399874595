

.account {

	.wrapper {

		width: 100%;
		max-width: 1200px;
		/*margin: 0 auto 20px;*/
		margin: 0 auto;
		padding: 60px 20px;
		box-sizing: border-box;

		@media screen and (max-width: $mediumBreakpoint) {

			padding: 60px 30px;
		}

		@media screen and (max-width: $tinyBreakpoint) {

			padding: 35px 20px;

			.title-2 {

				text-align: center;
			}
		}
	}


	.section {

		padding-bottom: 35px;
		overflow: auto;
	}


	.delete-account {

		padding-bottom: 0;

		.title-2 {

			margin-bottom: 20px;
		}

		.left-block {

			width: 100%;

			.reading-text:first-child {

				margin: 0 0 5px;
			}

		    @media screen and (min-width: $mediumBreakpoint) {
		        
				width: 60%; 
				float: left;
		    }
		}

		.right-block {

			width: 100%;
			margin-top: 20px;

		    @media screen and (min-width: $mediumBreakpoint) {
		        
				width: 40%; 
				margin: 0;
				float: left; 
				text-align: right;
		    }
		}

		.reading-text.error {

			font-family: $oxygen;
			font-weight: 300;
		}
	}


}