.wrapper.partials {

    .campaign-packages {

        margin-top: 40px;

        & > .title-3 {

            margin: 0 0 30px 0;

            @media screen and (max-width: 924px) {

                padding-left: 30px;
            }

            @media screen and (max-width: $tinyBreakpoint) {

                padding: 0 20px;
            }
        }
    }

    .swiper-container {

        .swiper-wrapper {

            .swiper-slide {

                width: 22.5%;

                &:nth-child(even) {

                    margin: 0 2.5%;

                    @media screen and (max-width: 924px) {

                        margin: 0;
                    }
                }
            }
        }
    }
}

.free-shipping {

    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    text-align: center;
    background: rgba(97,191,138,.75);
    color: white;
    font-family: $oxygen;
    font-size: 14px;
    font-weight: 600;
    z-index: 200;
    text-transform: uppercase;
    cursor: pointer;

.close {

    position: absolute;
    right: 20px;
    top: 10px;

&:after {

     content: "\e902";
     font: normal normal normal 10px/1 BeanIcons;
 }
}
}