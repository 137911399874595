
.register {

	.wrapper {

		width: 100%;
		max-width: 800px;
		margin: 5% auto;
		padding: 20px;
		box-sizing: border-box;
		text-align: center;
	}
}