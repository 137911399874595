.campaign-description {

    float: left;
    padding-right: 40px;
    box-sizing: border-box;
    width: 66.6667%;
    font-family: $oxygen;
    font-size: 16px;
    line-height: 1.4em;
    min-height: 1px;
    font-weight: 300;
    margin: 0;

    @media screen and (max-width: $smallBreakpoint) {

        width: 100%;
        border-right: none;
        padding-right: 0;
        margin-bottom: 35px;
    }

    @media screen and (max-width: $tinyBreakpoint) {

        p:first-child {

            margin-top: 0;
        }
    }

    h2 {

        font-family : $quicksand;
        font-weight: 400;
        font-size: 22px;
        margin-bottom: 30px;
    }

    h3 {

        font-family : $quicksand;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 20px;
    }

    a {

        color: $primaryColored;
        text-decoration: none;
    }
}

.campaign-last-donations {

    float: left;
    width: calc(33.3333% - 1px);
    border-left: 1px solid $thirdLight;
    padding-left: 40px;
    box-sizing: border-box;

    @media screen and (max-width: $smallBreakpoint) {

        width: 100%;
        padding-left: 0;
        border: none;
    }

    .reading-text {

        line-height: 1.4em;
    }

    .donation {

        display: flex;
        margin-top: 30px;
        clear: both;

        .avatar {

            float: left;
            margin: 5px 20px 5px 0;
            width: 50px;
            height: 50px;
        }

        .informations {

            float: left;
            width: calc(100% - 70px);

            .product {

                font-family: $quicksand;
                font-size: 16px;
                font-weight: 500;
            }

            .amount {

                font-family: $oxygen;
                font-weight: 500;
                font-size: 16px;
                margin-top: 5px;
                color: $primaryColored;
            }

            .name {

                font-family: $oxygen;
                font-size: 12px;
                margin-bottom: 5px;
            }
        }
    }
}