.campagin-groups {

    .title-3 {
        margin: 0 0 20px;

        @media screen and (min-width: $smallBreakpoint) {
            margin: 0 0 30px;
        }
    }

    .groups {

        .group {

            -js-display: flex;
            display: flex;
            align-items: center;
            padding: 20px 0;
            font-family: $oxygen;
            border-bottom: 1px solid $thirdLight;
            text-decoration: none;
            color: $primaryDark;

            &.podium {
                padding: 20px 50px 20px 0;
                background-repeat: no-repeat;
                background-position: right 5px center;
                background-size: auto 50px;

                &.podium-1 {
                    background-image: url('../images/ribbon_1.svg');
                }

                &.podium-2 {
                    background-image: url('../images/ribbon_2.svg');
                }

                &.podium-3 {
                    background-image: url('../images/ribbon_3.svg');
                }
            }

            &:hover {

                background-color: rgba($primaryColored, .05);
            }

            .avatar {

                margin: 0;
                width: 50px;
                height: 50px;
            }

            .name-div {
                margin-left: 10px;
                font-size: 14px;
                width: calc(100% - 60px);

                @media screen and (min-width: $smallBreakpoint) {
                    width: calc(100% - 65px);
                    margin-left: 15px;
                }

                .name {
                    width: 100%;
                    line-height: 18px;
                    font-weight: 600;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .price {
                    width: 100%;
                    line-height: 14px;
                    font-weight: 300;
                    margin-top: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .number {
                    margin-left: 5px;
                    color: $primaryColored;
                    font-weight: 600;
                }
            }

            &.itsme {
                //background-color: rgba($primaryColored, .25);
            }
        }
    }


    .more-groups {

        width: 100%;
        margin-top: 25px;
        font-size: 3em;
        font-weight: 300;
        line-height: .5em;
        padding: 7px;
    }
}