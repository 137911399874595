
.steps {

	text-align: center;
	margin: 0 auto;
	padding-bottom: 60px;

	@media screen and (max-width: 665px) {

		padding-bottom: 35px;
	}
}


.step {

	position: relative;
	display: inline-block;
	min-width: none;
	margin-left: -6px;
	padding: 14px;
	border: 2px solid $secondaryDark;
	border-radius: 2px;
	color: $secondaryDark;
	font-size: 12px;
	font-family: $quicksand;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	text-align: center;
	transition: all ease-out .2s;

	&:last-child {

		margin-left: 20px;

		@media screen and (max-width: 665px) {

			margin-left: 0;
			margin-top: 20px;
		}
	}

	&:hover {

		border-color: $primaryDark;
	}

    @media screen and (min-width: $smallBreakpoint) {
        
        min-width: 130px;
    }

    @media screen and (max-width: 665px) {

		width: 100%;
		box-sizing: border-box;
		margin-top: -2px;
		margin-left: 0;
    }

	&:after {
		position: absolute;
		bottom: -12px;
		left: 50%;
		margin-left: -7px;
		font: normal normal normal 12px/1 BeanIcons;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		padding: 6px;
		border-radius: 20px;

		@media screen and (max-width: 665px) {

			bottom: initial;
			left: initial;
			top: 21%;
			right: 10px;
		}
	}


	&.error {

		border-color: $errorColor;

		&:after {

			content: '\e902';
			background-color: $errorColor;
			color: $primaryLight;
		}
	}


	&.complete {

		&:after {

			content: '\e901';
			background-color: $primaryColored;
			color: $primaryLight;
		}
	}


	&.locked {

		&:hover {

			border-color: $secondaryDark;
			cursor: default;
		}

		&:after {

			content: '\e905';
			background-color: $secondaryDark;
			color: $primaryLight;
		}
	}


	&.optional {

		@media screen and (max-width: $tinyBreakpoint) {

			padding: 7px;
			vertical-align: top;
		}

		.option {
			text-transform: lowercase;
			color: $optional;

			@media screen and (max-width: $tinyBreakpoint) {

				display: block;
			}
		}
	}


	&.active {

		border-color: $primaryDark;
		color: $primaryColored;
		z-index: 1;


		&.optional {

			@media screen and (max-width: $tinyBreakpoint) {

				padding: 7px 10px;
			}
		}
	}
}