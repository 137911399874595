

.dropdown {

	position: absolute;
	top: 32px;
	right: 1px;
	min-width: 130px;
	padding: 15px 30px;
	background-color: rgba(255, 255, 255, 0.8);
	border: 1px solid $thirdLight;
	border-radius: 5px;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
	z-index: 1;

	&:before {

		content: '';
		position: absolute;
		top: -9px;
		right: 20px;
		width: 15px; 
		height: 15px; 
		border-left: 1px solid $thirdLight;
		border-top: 1px solid $thirdLight;
		background-color: $primaryLight;
		transform: rotate(45deg);
	}

	li {

		list-style: none;
	}

	.link {

		display: inline-block;
		padding: 8px 0;
		list-style: none;
		color: $primaryDark;
		font-size: .7em;
		font-family: 'Quicksand', 'Helvetica', Arial;
		font-weight: 700;
		line-height: 1.4em;
		text-transform: uppercase;
		text-decoration: none;

		&:hover {
			
			color: $primaryColored;
		}
	}
}