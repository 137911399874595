

.header {

	min-width: 100%;
	/*min-height: 80px;*/
	padding: 20px 30px 20px;
	box-sizing: border-box;
	border-bottom: 1px solid $thirdLight;
	overflow: visible;

	@media screen and (max-width: $smallBreakpoint) {

		padding: 10px 30px;
	}

	@media screen and (max-width: $tinyBreakpoint) {

		padding: 10px 20px;
	}


	&.simplified {

		border: none;

		 @media screen and (max-width: $tinyBreakpoint) {

		 	border-bottom: 1px solid $thirdLight;
		 }
	}
	

	.logo {

		display: inline-block;
		float: left;
		margin: 4px 0;
	}


	.profile {

		position: relative;
		display: inline-block;
		padding-right: 15px;
	}


	.button {

		display: inline-block;
		float: right;
		margin: 2px 0;
		width: auto;
	}

}