
.faq-left {

    float: left;
    width: calc(100% - 325px);
    padding-right: 40px;
    box-sizing: border-box;

    @media screen and (max-width: $smallBreakpoint) {

        width: 100%;
        padding-right: 0px;
    }

    .faq-questions {

        margin-bottom: 40px;

        .faq-question {

            margin-bottom: 30px;

            .reading-text {

                margin: 0 0 10px;

                &.bold {

                    font-weight: 700;
                }
            }

            ul {

                list-style-type: decimal;
                font-family: $oxygen;

                li {

                    padding-left: 5px;
                }
            }
        }
    }
}

.faq-right {

    float: left;
    width: 284px;
    padding-left: 40px;
    border-left: 1px solid $thirdLight;
    box-sizing: border-box;
    margin-top: 58px;

    @media screen and (max-width: $smallBreakpoint) {

        width: 100%;
        padding-left: 0;
        margin-top: 0;
        border-left: none;
        border-top: 1px solid $thirdLight;
        padding-top: 30px;
    }

    .faq-links {

        margin-bottom: 30px;
    }

    a.green {

        display: block;
        font-family: $oxygen;
        font-size: 12px;
        margin-bottom: 20px;
    }
}