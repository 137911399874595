
.home-full {

    position: relative;
    width: 100%;
    padding: 150px 20px;
    color: $primaryLight;
    text-align: center;
    box-sizing: border-box;

    background: url('../images/home_background2.jpg') no-repeat center center / cover;

    transition: padding ease-out .2s;

    @media screen and (max-width: $mediumBreakpoint) {

        padding: 100px 10%;
    }

    @media screen and (max-width: $tinyBreakpoint) {

        padding: 40px 20px;
    }

    .title-1 {

        margin: 0 0 20px 0;
        text-transform: none;
        color: $primaryLight;
    }

    .reading-text {

        margin: 0 auto 60px;
        font-family: $oxygen;
        color: $primaryLight;

        @media screen and (max-width: $tinyBreakpoint) {

            margin-bottom: 30px;
        }
    }

    .button, .button-bordered {

        margin: 10px 15px 0;
        width: auto;

        @media screen and (max-width: $tinyBreakpoint) {

            width: 100%;
            margin: 20px 0 0;
            padding: 10px;
        }
    }

    .cta-see-video {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-family: $quicksand;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: .86px;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        padding: 20px 20px 10px;
        background: url('../images/arrow-down-light.svg') no-repeat bottom center / 10px auto;
        cursor: pointer;
    }

    &.footer {

        background: url('../images/home_footer.jpg') repeat-y center center;
        padding: 100px 20px;
        background-size: cover;

        @media screen and (max-width: $tinyBreakpoint) {

            padding: 40px 20px;
        }
    }
}

.home-video {
    margin: 75px auto;
    width: 100%;
    max-width: 1024px;

    @media screen and (max-width: 1123px) {

        margin: 20px auto;
    }

    @media screen and (max-width: 1023px) {

        margin: 0;
    }

    .home-video-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.call-full {

    background-color: $primaryColored;
    padding: 75px 0;
    text-align: center;

    @media screen and (max-width: 767px) {

        padding: 40px 0;
    }

    .wrapper {

        padding: 0 20px !important;

        .call-full-item {

            float: left;
            width: 50%;
            margin-bottom: 50px;
            box-sizing: border-box;

            @media screen and (max-width: 767px) {

                margin-bottom: 20px;
                width: 100%;
            }

            &:nth-child(odd) {

                padding-right: 25px;
                clear: left;

                @media screen and (max-width: 767px) {

                    padding-right: 0;
                }
            }

            &:nth-child(even) {

                padding-left: 25px;

                @media screen and (max-width: 767px) {

                    padding-left: 0;
                }
            }

            &:nth-child(n+3) {

                margin-bottom: 0;

                @media screen and (max-width: 767px) {

                    margin-bottom: 20px;
                }
            }

            .call-left {

                float: left;
                margin-right: 30px;

                @media screen and (max-width: 767px) {

                    margin-right: 20px;
                }

                .icon {

                    width: 75px;
                    margin-bottom: 20px;

                    @media screen and (max-width: 767px) {

                        width: 50px;
                    }
                }
            }

            .call-right {

                float: left;
                width: calc(100% - 105px);

                @media screen and (max-width: 767px) {

                    width: calc(100% - 70px);
                }

                .title-2 {

                    margin-bottom: 20px;
                }

                .reading-text {

                    margin: 0;
                }
            }

            @media screen and (max-width: 767px) {

                &:last-child {

                    margin-bottom: 0;
                }
            }
        }
    }

    /*.title-3 {*/

        /*margin: 0 0 25px;*/
        /*color: $primaryLight;*/
    /*}*/

    /*.button-bordered {*/

        /*margin: 0;*/

        /*@media screen and (max-width: $tinyBreakpoint) {*/

            /*width: 100%;*/
        /*}*/
    /*}*/
}

.campaign .wrapper {

    position: relative;
    padding: 60px 20px;
    text-align: left;

    @media screen and (max-width: $mediumBreakpoint) {

        padding: 35px 30px;
    }

    @media screen and (max-width: $tinyBreakpoint) {

        padding: 35px 20px;
    }

    &.fullwidth {

        @media screen and (max-width: $smallBreakpoint) {

            padding-left: 0;
            padding-right: 0;

            .title-1 {

                padding: 0 20px;
                text-align: center;
            }
        }

        @media screen and (max-width: $tinyBreakpoint) {

            .centered-button {

                padding: 0 20px;
            }
        }
    }

    @media screen and (max-width: $tinyBreakpoint) {

        padding-top: 35px;
        padding-bottom: 35px;
        margin: 0;

        .title-1 {

            text-align: center;
            font-size: 1.8em;
        }

        .text-center .button-bordered {

            width: calc(100% - 40px);
        }
    }

    .title-1 {

        margin: 0 0 30px;
        text-transform: none;
    }

    .favorite-content {

        -webkit-transition: height ease-out .2s;
        -moz-transition: height ease-out .2s;
        -ms-transition: height ease-out .2s;
        -o-transition: height ease-out .2s;
        transition: height ease-out .2s;
    }

    .filters {

        margin-bottom: 20px;

        .select-group {

            float: left;
            width: 49.5%;
            &:nth-child(2) {

                margin-right: 1%;
            }

            @media screen and (max-width: $tinyBreakpoint) {

                width: 100%;
                &:nth-child(2) {

                     margin-right: 0;
                 }
            }
        }

        &.home {

            position: absolute;
            right: 20px;
            top: 65px;

            @media screen and (max-width: $mediumBreakpoint) {

                position: initial;
                text-align: center;
                margin-bottom: 30px;
            }

            .select-group {

                display: inline-block;
                margin-left: 10px;

                .input.select {

                    padding: 10px 29px 10px 10px;
                }
            }
        }

        &.explore {

            .input.select.dark {

                font-size: 16px;
                padding-left: 20px;
            }

            .select-group:after {

                 font-size: 16px;
            }
        }
    }

    .filtersHome {

         position: absolute;
         right: 20px;
         top: 65px;

        @media screen and (max-width: $mediumBreakpoint) {

            position: initial;
            text-align: center;
            margin-bottom: 30px;
        }

        .select-group {

            display: inline-block;
            margin-left: 10px;

            .input.select {

                padding: 10px 29px 10px 10px;
            }

            @media screen and (max-width: $tinyBreakpoint) {

                width: 100%;
                padding: 0 20px 10px;
                margin: 0;

                &:after {

                    right: 33px;
                }
            }
        }
    }

    .alert {

        margin-bottom: 50px;
    }
}

.colored-section {

    border-top: 1px solid $thirdLight;
    border-bottom: 1px solid $thirdLight;
    background-image: url('../images/background_texture.png');
    background-position: center center;
    background-repeat: repeat;
    background-size: cover;
}