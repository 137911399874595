.details-wrapper {
    width: calc(100% - 40px);
    max-width: 980px;
    margin: 40px auto;

    @media screen and (min-width: $mediumBreakpoint) {
        margin: 60px auto;
    }
}

.details-hero {
    padding: 40px 0;
    background-color: $secondaryLight;

    @media screen and (min-width: $mediumBreakpoint) {
        padding: 60px 0;
    }

    .details-wrapper {
        margin: 0 auto;
    }
}

.details-title {
    margin: 0 0 40px;

    @media screen and (min-width: $smallBreakpoint) {
        justify-content: space-between;
        flex-direction: row;
        margin: 0 0 60px;
    }

    @media screen and (min-width: $mediumBreakpoint) {
        margin: 0 0 60px;
    }
}

.details-stats {
    -js-display:flex;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $smallBreakpoint) {
        justify-content: space-between;
        flex-direction: row;
    }

    .details-stats-item {
        text-align: center;
        margin-bottom: 20px;

        @media screen and (min-width: $smallBreakpoint) {
            margin-bottom: 0;
        }
    }

    .details-stats-item-value {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 2px;
        font-weight: 700;
        font-family: $oxygen;
        margin: 0 0 10px;
        color: $primaryColored;

        @media screen and (min-width: $mediumBreakpoint) {
            font-size: 40px;
            line-height: 50px;
            letter-spacing: 2.5px;
        }
    }

    .details-stats-item-text {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        font-family: $oxygen;
        margin: 0;

        @media screen and (min-width: $mediumBreakpoint) {
            line-height: 25px;
        }
    }
}

.details-grid {
    -js-dsplay: flex;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $mediumBreakpoint) {
        flex-direction: row;
    }

    .details-grid-left {
        width: 100%;

        @media screen and (min-width: $mediumBreakpoint) {
            width: calc(100% - 315px);
            margin-right: 40px;
        }
    }

    .details-grid-right {
        width: 100%;

        @media screen and (min-width: $mediumBreakpoint) {
            width: 315px;
        }
    }
}

.details-illustration {
    width: 100%;
    margin: 0 0 20px;

    @media screen and (min-width: $smallBreakpoint) {
        margin: 0 0 60px;
    }

    .details-illustration-image{
        width: 100%;
        height: auto;
    }

    .details-illustration-video{
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.details-description {
    box-sizing: border-box;
    font-family: $oxygen;
    font-size: 14px;
    line-height: 25px;
    font-weight: 300;
    margin: 0 0 20px;

    p {
        margin: 0 0 20px;
    }

    h2 {
        font-family: $quicksand;
        font-weight: 300;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 20px;

        @media screen and (min-width: $smallBreakpoint) {
            margin-bottom: 30px;
        }
    }

    h3 {
        font-family: $quicksand;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
    }

    a {
        color: $primaryColored;
        text-decoration: none;
    }
}

.details-tags-share {
    -js-display: flex;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    border-top: 1px solid $thirdLight;
    border-bottom: 1px solid $thirdLight;
    margin-bottom: 40px;

    @media screen and (min-width: $smallBreakpoint) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.details-tags {
    -js-display: flex;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    @media screen and (min-width: $smallBreakpoint) {
        margin-bottom: 0;
        justify-content: flex-start;
    }
}

.details-share {
    -js-display: flex;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $smallBreakpoint) {
        justify-content: flex-end;
    }

    .text {
        font-family: $oxygen;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 1px;
        margin-right: 5px;
    }

    a {
        display: block;
        width: 32px;
        height: 32px;
        margin-left: 10px;

        &.details-share-facebook {
            background: url('../images/share-facebook.svg') no-repeat center center / contain;
        }

        &.details-share-twitter {
            background: url('../images/share-twitter.svg') no-repeat center center / contain;
        }
    }
}

.details-packages-wrapper {
    margin: 0 0 20px;

    .details-packages-title {
        margin: 0 0 20px;
    }

    .details-packages-text {
        margin: 0 0 40px;
        padding: 15px 20px;
        background-color: $secondaryLight;

        @media screen and (min-width: $smallBreakpoint) {
            margin: 0 0 60px;
        }
    }

    .details-packages {
        -js-display: flex;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        @media screen and (min-width: $smallBreakpoint) {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;
        }

        @media screen and (min-width: $mediumBreakpoint) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }
}

.details-package {
    position: relative;
    padding: 30px;
    border: 1px solid $thirdLight;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: $oxygen;
    margin-bottom: 20px;
    width: 100%;

    @media screen and (min-width: $smallBreakpoint) {
        width: calc(50% - 12px);
        margin-right: 20px;

        &:nth-child(2n+2) {
            margin-right: 0;
        }
    }

    @media screen and (min-width: $mediumBreakpoint) {
        width: 100%;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .package-infos {
        -js-display: flex;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .package-image {
            width: 80px;
            margin-right: 15px;
        }

        .package-name-wraper {
            width: calc(100% - 95px);

            .package-price {
                font-size: 22px;
                line-height: 28px;
                letter-spacing: 1.38px;
                font-weight: 700;
                margin: 0 0 5px;
            }

            .package-donation {
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 1px;
                font-weight: 700;
                margin: 0;

                span {
                    color: $primaryColored;
                }
            }
        }
    }

    .package-name {
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        margin: 0 0 10px;
    }

    .package-description {
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 1px;
        font-weight: 300;
        margin: 0;
    }

    .details-package-hover-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($primaryColored, .95);
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity ease-out .2s;
        display: none;

        &:hover {
            opacity: 1;
        }

        @media screen and (min-width: $smallBreakpoint) {
            -js-display: flex;
            display: flex;
        }
    }

    .package-mobile-button {
        display: block;
        margin: 20px 0 0;

        @media screen and (min-width: $smallBreakpoint) {
            display: none;
        }
    }
}