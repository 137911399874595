

.main-navigation {
	
	display: none;
	float: left;
	margin: 11px 0;
	padding-left: 20px;

    @media screen and (min-width: $mediumBreakpoint) {
        
		display: inline-block;
    }


	.link {

		display: inline-block;
		padding: 0 20px;
		color: $primaryDark;
		font-size: 12px;
		font-family: $quicksand;
		font-weight: 700;
		line-height: 1.4em;
		text-transform: uppercase;
		text-decoration: none;
		transition: color ease-out .2s;
	
		&:hover {
		
			color: $primaryColored;
		}

		@media screen and (max-width: 1154px) {

			&.media {

				display: none;
			}
		}
	}


	.display_search {

		&:after {

			content: "\e908";
			font: normal normal normal 16px/1 BeanIcons;
		}
	}
}




.secondary-navigation {

	display: none;
	float: right;

    @media screen and (min-width: $mediumBreakpoint) {
        
		display: block;
    }


	.dropdown {

		display: none;
		transition: all .5s ease;

		&.visible {

			display: inline-block;
		}
	}


	.auth-link {

		display: inline-block;
		padding: 14px 20px 0;
		color: $primaryDark;
		font-size: .7em;
		font-family: $quicksand;
		font-weight: 700;
		line-height: 1.4em;
		text-transform: uppercase;
		text-decoration: none;
	
		&:hover {
		
			color: $primaryColored;
		}
	}
}




.mobile-navigation {
	
	display: block;
	float: right;
	padding-top: 2px;
	color: $primaryDark;
	font-size: 1em;
	font-family: $quicksand;
	font-weight: 700;
	/*line-height: 1.4em;*/
	text-transform: uppercase;

    @media screen and (min-width: $mediumBreakpoint) {
        
		display: none;
    }

	@media screen and (max-width: $smallBreakpoint) {

		padding-top: 8px;
	}


    .inner {
	
		display: none;
		position: fixed;
		-webkit-overflow-scrolling: touch;
		transform: translateZ(0);
		top: 0;
		min-height: 100%;
		left: 0;
		right: 0;
		padding: 100px 30px 60px;
		background-color: rgba(255, 255, 255, 1);
		text-align: center;
		z-index: 2;

		&.open {

			display: block;
		}

		@media screen and (max-width: $tinyBreakpoint) {

			padding: 100px 20px 60px;
		}
    }


  	.link {

		display: inline-block;
		padding: 0 0 0 10px;
		color: $primaryDark;
		font-size: .7em;
		font-family: $quicksand;
		font-weight: 700;
		/*line-height: 1.4em;*/
		text-transform: uppercase;
		text-decoration: none;
	
		&:hover {
		
			color: $primaryColored;
		}

		&.full-width {
			
			display: block;
			padding: 15px 0;
			font-size: 1em;
			text-align: center;

		    @media screen and (min-width: $smallBreakpoint) {
		        
				font-size: 1.4em;
		    }
		}
	}

	
	.button {

		margin: 40px auto 10px;
		font-size: 12px;
		float: inherit;

	    @media screen and (min-width: $smallBreakpoint) {
	        
			display: inline-block;
			width: auto !important;
			float: none;
	    }

		&.mobile-button {

			display: inline-block;
			width: auto;
			padding: 10px 40px 9px;
			margin: 0 10px;

			@media screen and (max-width: $smallBreakpoint) {

				display: none;
			}
		}
	}
	

	.menu {

		border: none;
		background-color: transparent;
		font-size: 1.1em;

		.icon {

			padding-left: 3px;
			font-size: 1em;
		}
	}


	.display_search {

		&:after {

			content: "\e908";
			font: normal normal normal 16px/1 BeanIcons;
		}
	}


	.close {

		position: absolute;
		top: 10px;
		right: 30px;
		border: none;
		background: none;
		padding: 8px 0;

		&:after {

			content: "\e902";
			font: normal normal normal 16px/1 BeanIcons;
		}

		@media screen and (max-width: $tinyBreakpoint) {

			right: 20px;
		}
	}


	.title {

		position: absolute;
		top: 14px;
		left: 30px;
		border: none;
		background-color: transparent;
		font-size: 1.4em;

		@media screen and (max-width: $tinyBreakpoint) {

			left: 20px;
		}
	}


	.select-group {

		display: inline-block;

		&:after {

			top: 39px;
			right: 30px;
		}
	}

	
	.select {

		display: inline-block;
		width: auto;
		margin: 25px 20px 20px 0;
		padding: 8px 30px 8px 10px;
		background-color: transparent;
		font-size: 1em;
	}
}



@media screen and (max-width: $tinyBreakpoint) {

	.header.simplified {

		text-align: center;

		.logo {

			float: inherit;
		}
	}
}