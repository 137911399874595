
.donations {
    margin-top: 50px;
    font-family: $quicksand;
    text-align: left;
    color: $primaryDark;

    .bigCell {

        width: 60%;
        float: left;
        line-height: 26px;

        @media screen and (max-width: $smallBreakpoint) {

            padding: 0 5px;
            box-sizing: border-box;
        }

        @media screen and (max-width: $tinyBreakpoint) {

            padding: 0 0 20px;
            width: 100%;
        }
    }

    .smallCell {

        float: left;
        width: 20%;
        line-height: 26px;

        @media screen and (max-width: $tinyBreakpoint) {

            width: 50%;
        }

        &.price {

            font-family: $oxygen;
            font-size: 22px;
            font-weight: 500;
            color: $primaryColored;
        }
    }

    .donations-header {

        height: 50px;
        border-bottom: 1px solid $thirdLight;

        .bigCell, .smallCell {

            font-size: 18px;
            font-weight: 500;
        }

        @media screen and (max-width: $tinyBreakpoint) {

            display: none;
        }
    }

    .donation {

        clear: left;
        padding-top: 40px;

        @media screen and (max-width: $tinyBreakpoint) {

            display: inline-block;
            padding: 25px 0;
            margin: 0;
            width: 100%;
            border-top: 1px solid $thirdLight;
        }
    }

}

.reading-text.none {
    padding-top: 25px;
}