
#search {

    display: none;

    position: fixed;
    -webkit-overflow-scrolling: touch;
    transform: translateZ(0);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255,.95);
    z-index: 9999;
    padding: 100px 200px 50px 200px;

    @media screen and (max-width: $mediumBreakpoint) {

        padding: 100px 100px 50px 100px;
        top: 77px;
    }

    @media screen and (max-width: $smallBreakpoint) {

        padding: 50px;
        top: 57px;
        overflow-y: scroll;
    }

    @media screen and (max-width: $tinyBreakpoint) {

        padding: 20px;
    }

    .field {

        .text{

            width: 100%;
            background: none;
            font-size: 64px;
            font-family: $quicksand;
            color: $searchColor;
            border: none;
            -webkit-appearance: none;

            @media screen and (max-width: $tinyBreakpoint) {

                font-size: 32px;
            }
        }
    }

    .keywords {

        margin-top: 40px;

        .tag {

            float: left;
            background: rgba(255,255,255,.75);
            padding: 10px 15px;
            margin: 0 15px 15px 0;
            font-size: 12px;
            border-radius: 5px;

            @media screen and (max-width: $tinyBreakpoint) {

                padding: 7px 10px;
                border-radius: 3px;
                margin: 0 10px 10px 0;
            }
        }
    }


    .label {

        padding: 0;
        margin-bottom: 20px;
        font-size: 18px;
        font-family: $quicksand;
        color: $primaryDark;
        font-weight: 400;

        @media screen and (max-width: $tinyBreakpoint) {

            font-size: 14px;
        }
    }

    .delete-search {

        position: fixed;
        -webkit-overflow-scrolling: touch;
        transform: translateZ(0);
        top: 50px;
        right: 50px;
        cursor: pointer;

        &:after {

            content: "\e902";
            font: normal normal normal 25px/1 BeanIcons;
        }

        @media screen and (max-width: $smallBreakpoint) {

            display: none;
        }
    }
}