
/*
 * 		External Ressources
 * 		-------------------
 */

@import '//fonts.googleapis.com/css?family=Quicksand:400,300,700';
@import '//fonts.googleapis.com/css?family=Oxygen:400,300,700';
@import '//cdnjs.cloudflare.com/ajax/libs/Swiper/3.3.1/css/swiper.min.css';




/*
 * 		Settings
 * 		--------
 */


@import 'settings';




/*
 * 		Reset
 * 		-----
 */


@import 'reset';




/*
 * 		Components
 * 		----------
 */


@import 'components/typography';
@import 'components/button';
@import 'components/form';
@import 'components/dropdown';
@import 'components/avatar';
@import 'components/step';
@import 'components/alert';
@import 'components/invitation';
@import 'components/progressBar';
@import 'components/tag';




/*
 * 		Modules
 * 		-------
 */


@import 'modules/block';
@import 'modules/header';
@import 'modules/footer';
@import 'modules/navigation';
@import 'modules/campaign';
@import 'modules/donations';
@import 'modules/illustration';
@import 'modules/listingCampaign';
@import 'modules/homeSwiper';
@import 'modules/package';
@import 'modules/search';
@import 'modules/block-info';




/*
 * 		Templates
 * 		---------
 */


@import 'templates/common';
@import 'templates/auth';
@import 'templates/register';
@import 'templates/account';
@import 'templates/newCampaign';
@import 'templates/campaign';
@import 'templates/summary';
@import 'templates/home';
@import 'templates/single';
@import 'templates/partialDescription';
@import 'templates/partialPackages';
@import 'templates/partialContributions';
@import 'templates/partialGroups';
@import 'templates/orderIndex';
@import 'templates/exploration';
@import 'templates/faq';
@import 'templates/404';
@import 'templates/about';
@import 'templates/terms';
@import 'templates/details';

