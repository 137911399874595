
.input-group {
	
	position: relative;
	/*max-width: 500px;*/
	margin: 0 auto;
	padding-bottom: 10px;
	box-sizing: border-box;
	text-align: left;

	&.opacity {

		opacity: 0.5;
	}


	&.margin {

		padding: 20px 0;
	}


	&.one-column {

		float: left;
		width: 25%;		
	}


	&.two-column {

		float: left;
		width: 50%;		
	}


	&.three-column {

		float: left;
		width: 75%;		
	}

	.error-message {

		padding-top: 10px;
	}

	.preview-error {

		display: none;
	}

	&.group_preview.error {

		.preview {

			border-color: $errorColor;
			background-color: rgba(255,0,0,.1);
		}

		.preview-error {

			display: block;
		}
	}
}




.select-group {
	
	position: relative;
	max-width: 500px;
	margin: 0 auto;
	padding-bottom: 10px;
	box-sizing: border-box;
	text-align: left;

	&:after {
	
		content: "\e900";
		font: normal normal normal 10px/1 BeanIcons;
		position: absolute;
		top: 13px;
		right: 10px;
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
		pointer-events: none;
	}


	&.opacity {

		opacity: 0.5;
	}


	&.margin {

		padding: 20px 0;
	}


	&.one-column {

		float: left;
		width: calc(25% - 20px);	
		margin-left: 20px;	
	}
}




.input {

	display: block;
	width: 100%;
	margin: 0 auto;
	padding: 10px 5px;
	box-sizing: border-box;
	border: none;
	border-bottom: 1px solid $secondaryDark;
	background-color: transparent;
	color: $primaryColored;
	font-size: 22px;
	font-family: $oxygen;
	font-weight: 300;
	border-radius: 0;

	&.error {

		border-color: $errorColor;
		background-color: rgba(255, 0, 0, 0.1);
		color: $errorColor;
	}


	&.has-indice {

		padding: 10px 70px 10px 5px;
	}
}




.textarea {
	
	width: 100%;
	box-sizing: border-box;
	margin-top: 25px;
	padding: 20px;
	border: 1px solid $secondaryDark;
}




.select {
	
	position: relative;
	padding: 10px 30px 10px 10px;
	box-sizing: border-box;
	border: 1px solid $secondaryDark;
	background-color: $primaryLight;
	-webkit-appearance: none;
	-moz-appearance : none;
	transition: opacity ease-out .2s;
	font-family: $oxygen;
	text-transform: uppercase;
	font-size: 12px;
	border-radius: 5px;


	&.dark {

		color: $primaryDark;
		font-weight: 700;
	}


	&:hover {

		opacity: .9;
	}
}



.radio {

	position: relative;
	display: block;
	float: left;
	padding: 0 5px;
	margin: 30px 0 40px;
	cursor: pointer;

    @media screen and (min-width: $mediumBreakpoint) {
        
        margin: 0;
        padding: 40px 10px;
    }


	input {

		border: 0; 
		clip: rect(0 0 0 0); 
		height: 1px; 
		margin: -1px; 
		overflow: hidden; 
		padding: 0; 
		position: absolute; 
		width: 1px;
	}
	
	span {

		position: relative;
		padding: 15px;
		background-color: transparent;
		color: $secondaryDark;
		font-size: 12px;
		font-family: $quicksand;
		font-weight: 700;
		text-transform: uppercase;
		transition: background 0.2s ease;

	    @media screen and (min-width: $mediumBreakpoint) {
	        
	        padding: 20px;
	    }

		&:before {

			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border: 2px solid $secondaryDark;
			border-radius: 2px;
			transition: border 0.05s ease, background 0.2s ease;
		}

		&:hover {

			background-color: white;

			&:before {

				 border-color: $primaryDark;
			}

			&:disabled {

				background-color: transparent;

				&:before {

					border-color: $secondaryDark;
				}
			}
		}
	}


	input:checked {

		& + span {

			background-color: $primaryLight;
			color: $primaryDark;

			&:before {

				border: 2px solid $primaryDark;
			}	
		}
	}


	.recommand {
	
		position: absolute; 
		bottom: -35px; 
		left: 50%;
		transform: translate(-50%, 0%);
		color: $primaryColored;
		font-size: 12px;
		font-family: $oxygen;
		font-weight: 700;
		font-style: normal;

	    @media screen and (min-width: $mediumBreakpoint) {
	        
	        bottom: -5px;
	    }
	}
}



.label {

	display: block;
	padding: 5px;
	color: $primaryDark;
	font-size: 16px;
	font-family: $oxygen;
	font-weight: 600;
	line-height: 1.2em;
	text-align: left;

	.tag {

		color: $thirdLight;
		text-transform: lowercase;
	}
}




.indice {

	position: absolute;
	top: 65px;
	right: 2px;
	color: $primaryColored;
	font-size: 12px;
	font-family: $oxygen;
	font-weight: 700;

	&.warning {

		color: rgba(230, 145, 25, 1);
	}

	&.error {

		color: rgba(255, 0, 0, 0.8);
	}
}




.filled-field {

	padding: 0 5px;
	color: $primaryColored;
	font-size: 1.175em;
	font-family: $quicksand;
	font-weight: 400;
	line-height: 1.2em;
	text-align: left;	
}



.error-message {

	color: $errorColor;
	font-size: 1em;
	font-family: $quicksand;
	font-weight: 400;
	text-align: left;
	clear: left;

	.fa {
		
		display: inline-block;
		padding-right: 5px;
		opacity: 1;
		font-size: .875em;
	}
}




.newsletter-field {
	
	float: left;
	width: 80%;
	margin-top: 10px;
	padding: 10px;
	background-color: $primaryLight;
	border: 1px solid $primaryColored;
	border-radius: 5px 0 0 5px;
	font-size: .875em;
	font-family: $oxygen;
	font-weight: 400;
	box-sizing: border-box;

	&.error {

		border: 1px solid $errorColor;
	    background-color: rgba(255, 0, 0, 0.1);
	}

	@media screen and (max-width: $tinyBreakpoint) {

		width: 70%;
	}
}

.newsletter-submit {

	float: left;
	margin-top: 10px;
	padding: 11px;
	background-color: $primaryColored;
	border: 1px solid $primaryColored;
	border-radius: 0 5px 5px 0;
	color: $primaryLight;
	font-size: .8em;
	font-family: $quicksand;
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;
	-webkit-appearance: none;
	transition: all ease-out .2s;
	width: 20%;
	border-left: none;
	box-sizing: border-box;

	&:hover {
	
		background-color: $primaryLight;
		color: $primaryColored;
	}

	&.error {

		border-left: 1px solid $errorColor;
	}

	@media screen and (min-width: $tinyBreakpoint) {

		padding: 11px 5px;
	}

	@media screen and (max-width: $tinyBreakpoint) {

		width: 30%;
	}
}




.input-edit-group {
	
	position: relative;
	width: 100%;
	margin: 20px auto 0;
	padding: 15px 20px;
	box-sizing: border-box;
	background-color: $secondaryLight;
	border: 1px solid $thirdLight;
	border-radius: 2px;
	text-align: left;
	overflow: auto;

    @media screen and (min-width: $smallBreakpoint) {
        
    	overflow: visible;
    }

	&.opacity {

		opacity: 0.5;
	}


	&.margin {

		padding: 20px 0;
	}


	&.one-column {

		float: left;
		width: 25%;		
	}


	&.two-column {

		float: left;
		width: 50%;		
	}


	&.three-column {

		float: left;
		width: 75%;		
	}

	.select-group {

		display: inline-block;
		margin: 0;
		padding: 0;

		&:after {
		
			top: 24px;
		}
	}

	.select {

		display: inline-block;
		width: auto;
		min-width: 80px;
		margin: 10px 0 20px;
	}

	.active-input-button {

		display: none;
	}

	.save-input-button,
	.reset-input-button {

		display: inline-block;
	}
}

.input-edit {

	display: inline-block;
	width: 100%;
	max-width: 100%;
	margin: 0 auto 20px;
	padding: 0 5px;
	box-sizing: border-box;
	border: none;
	border-bottom: 1px solid $secondaryDark;
	background-color: transparent;
	color: $primaryColored;
	font-size: 1.2em;
	font-family: $oxygen;
	font-weight: 300;
	overflow: auto;
	border-radius: 0;


	&.error {

		border-color: $errorColor;
		background-color: rgba(255, 0, 0, 0.1);
		color: $errorColor;
	}


	&.has-indice {

		padding: 10px 70px 10px 5px;
	}


    @media screen and (min-width: $smallBreakpoint) {

		margin-bottom: 0;


		&.small {

			width: 100%;
			max-width: 30%;
		}


		&.medium {

			width: 100%;
			max-width: 50%;
		}


		&.large {

			width: 100%;
			max-width: 65%;
		}
    }
}


.input-edit-group.disabled {

	.input-edit {

		border: none;
		color: $primaryColored;
		-webkit-text-fill-color:$primaryColored;
		opacity: 1;
	}


	.select {

		padding: 0 5px 5px 5px;
		border: none;
		background-color: transparent;
		color: $primaryColored;
	    font-size: 1.075em;
	    font-weight: 400;
	}

	.select-group:after {

		display: none;
	}

	.active-input-button {

		display: inline-block;
	}


	.save-input-button,
	.reset-input-button {

		display: none;
	}
}


.input-edit-group .aside {
	
	float: left;

    @media screen and (min-width: $smallBreakpoint) {

		position: absolute;
	    top: -8px;
	    right: 0;
	    margin: 0;
	    padding: 20px 20px;
    }

    @media screen and (max-width: $tinyBreakpoint) {

		width: 100%;
		text-align: center;
    }

	.button,
	.button-bordered {
	
		width: 100%;
		padding: 10px 40px 9px;
		font-size: 12px;

	    @media screen and (min-width: $smallBreakpoint) {

			margin: 23px 0 23px 20px;
			width: auto;
	    }

	    @media screen and (max-width: $tinyBreakpoint) {

			margin: 15px 0 0 0;
	    }
	}
}




.input-side-group {

	display: table;
	width: 100%;
	max-width: 100%;
	margin: 40px auto;
	padding: 20px;
	box-sizing: border-box;
	background-color: $secondaryLight;
	border: 1px solid $thirdLight;

    @media screen and (min-width: $smallBreakpoint) {

		padding: 0 5px;
		border: none;
		background-color: transparent;
    }

    @media screen and (max-width: $tinyBreakpoint) {

		margin: 0 0 20px;
    }


	.label-block {
		
		display: block;
		width: 100%;
		padding-right: 40px;
		box-sizing: border-box;
		color: $primaryDark;
		font-size: 1.2em;
		font-family: $quicksand;
		font-weight: 400;
		text-align: left;

	    @media screen and (min-width: $smallBreakpoint) {

			display: table-cell;
			width: 35%;
	    }

		.label {

			padding: 5px 0 0;
		}

		.reading-text {

			margin: 10px 0;
			padding: 0;
			font-size: 0.7em;
		}
	}

	.input-block {
		
		display: block;
		width: 100%;
		margin-top: 20px;
		box-sizing: border-box;
		border-radius: 2px;
		vertical-align: middle;

	    @media screen and (min-width: $smallBreakpoint) {

			display: table-cell;
			width: 65%;
			padding: 20px;
			background-color: $secondaryLight;
			border: 1px solid $thirdLight;
	    }


	    &.error {

	    	padding-top: 25px;


	    	.radio span {

				&:before {

					border-color: $errorColor;
					background-color: rgba(255, 0, 0, 0.1);
				}

			}
	    }


		.input-group {

			padding: 0;
		}


		.select-group {

			max-width: none;
			margin-top: 10px;
			padding: 0;
		}


		.indice {

			top: 15px;
		}


		.error-message {

			margin-bottom: 0;
		}
	}

}




.input-file {

    position: relative;
    overflow: hidden;
    margin: 0 0 15px;

	input {

	    position: absolute;
	    top: 0;
	    left: 0;
	    margin: 0;
	    padding: 0;
	    font-size: 3em;
	    cursor: pointer;
	    opacity: 0;
	    filter: alpha(opacity=0);
	}
}




.preview {

	position: relative;
	width: 100%;
	/*min-height: 355px;*/
	height: 355px;
	margin-top: 10px;
	box-sizing: border-box;
	background-color: $primaryLight;
	border: 1px dashed $secondaryDark;

	@media screen and (max-width: $tinyBreakpoint) {

		height: 230px;
	}


	&.image {
	
		background-size: cover;
		background-position: center;
	}


	&.video {

		margin-top: 20px;

		iframe {

			width: 100%;
			height: 355px;
		}
	}

	&.loading {

		.loader {

			position: absolute;
			display: block;
			top:15px;
			left:0;
			right:0;
			text-align: center;

			img {

				width: 50px;
			}
		}
	}

	&.error {

		.error {

			display: block;
			position: absolute;
			top:20px;
			left:0;
			right: 0;
			text-align: center;
			color: $errorColor;
			font-family: $oxygen;
		}
	}


	.infos {

		position: absolute;
		top: 40%;
		left: 50%;
		width: 100%;
		padding: 20px;
		box-sizing: border-box;
		transform: translate(-50%, -50%);
		color: $secondaryDark;
		font-size: .875em;
		font-family: $oxygen;
		font-weight: 400;

		span {

			display: block;
			margin-top: 15px;
			text-align: center;
		}

		.icon {

			margin-top: 25px;
			color: $primaryDark;
			font-size: 2em;

			&.upload:before {

				font-family: BeanIcons;
				content: '\e907';
			}

			&.video:before {

				font-family: BeanIcons;
				content: '\e90b';
			}
		}
	}

	.loader {

		display: none;
	}

	.error {

		display: none;
	}

}

.checkbox {

	input {

		float: left;
		cursor: pointer;
		width: 36px;
		height: 36px;
		background: url('../images/checkbox.svg') left top no-repeat;
		margin: 0;
		-webkit-appearance: inherit;
		border: none;

		&:checked {

			background-position: right top;
		}
	}

	&.error input {

		background-position: left bottom;

		&:checked {

			background-position: right top;
		}
	}

	label {

		display: flex;
		cursor: pointer;
		line-height: 36px;
		padding-left: 15px;

		@media screen and (max-width: $tinyBreakpoint) {

			line-height: 20px;
		}
	}
}

.loginForm {

	input {

		margin-bottom: 10px;
		&.error {

			margin-bottom: 0;
		}
	}

	&#registerForm {

		display: none;
	}
}

.label-optional {

	color: $optional;
}