
.campaigns {

    .campaign {

        width: 31.66667%;
        float: left;

        &:nth-child(3n+2) {

            margin: 0 2.5%;
        }

        @media screen and (max-width: $smallBreakpoint) {

            width: 48.75%;

            /*&:nth-child(3n+2) {*/

                /*margin: 0;*/
            /*}*/

            &:nth-child(2n+2) {

                margin: 0 0 0 2.5%;
            }
        }

        @media screen and (max-width: $tinyBreakpoint) {

            width: 100%;

            &:nth-child(2n+2) {

                margin: 0;
            }
        }

        .title-3 {

            overflow: hidden;
            height: 75px;
        }
    }
}