

.wrapper.legal {

    .terms_conditions {

        font-family: $oxygen;
        list-style-type: decimal;
        color: $primaryDark;

        li {

            padding-left: 10px;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 20px;

            &>ul {

                margin-top: 10px;
            }
        }
    }

    .reading-text {

        margin-bottom: 20px;
    }
}