
.auth {

	.wrapper {

		width: 100%;
		max-width: 520px;
		margin: 40px auto;
		padding: 20px;
		box-sizing: border-box;
		text-align: center;
	
		&.large {

			max-width: 900px;
			margin: 10% auto;
		}

		.navigation {

			text-align: left;
		}
	}

	.forgot {

		position: relative;

		input {

			padding-right: 70px;
		}

		.green {

			position: absolute;
			top: 20px;
			right: 0;
			font-family: $oxygen;
			font-size: 16px;
		}
	}

	.button.facebook {

		@media screen and (max-width: $tinyBreakpoint) {

			font-size: 13px !important;
		}
	}
}