

#footer {

	min-width: 100%;
	min-height: 80px;
	box-sizing: border-box;
	background-color: $secondaryLight;
	border-top: 1px solid $thirdLight;
	clear: both;


	.wrapper {

		max-width: 1200px !important;
		margin: 0 auto;
		padding: 60px 30px;
		text-align: left;
		overflow: auto;

	    @media screen and (min-width: $smallBreakpoint) {

			max-width: 800px;
	    }

	    @media screen and (max-width: $tinyBreakpoint) {

			padding: 20px;
	    }
	}


	.navigation {
		
		width: 100%;
		margin-top: 20px;
		padding: 0 0 20px;
		box-sizing: border-box;
		border-bottom: 1px solid $thirdLight;

		&:nth-child(1) {
			
			margin-top: 0;
		}

		&:last-child {

			border-bottom: none;
		}

	    @media screen and (min-width: $smallBreakpoint) {
	        
	        float: left;
	        width: 50%;
	        padding: 0 20px 20px;
	        border-bottom: none;

			&:nth-child(2) {
				
				margin-top: 0;
			}

			&:nth-child(3),
			&:nth-child(4) {
				
				padding-bottom: 0;
			}

	    }

	    @media screen and (min-width: $mediumBreakpoint) {
	        
	        float: left;
	        width: 25%;
	        margin-top: 0;
			padding: 0 20px 0 0;
	    }

	    @media screen and (max-width: $mediumBreakpoint) {

	        padding: 0 20px 0 0;
	    }

	}


	.link {

		display: block;
		padding: 5px 0;
		color: $primaryDark;
		font-size: 16px;
		font-family: $oxygen;
		font-weight: 300;
		line-height: 1.4em;
		text-decoration: none;
		transition: color ease-out .2s;
	
		&:hover {
		
			color: $primaryColored;
		}
	}


	.select-group {

		display: inline-block;

		&:after {

			top: 12px;
			right: 10px;
		}
	}

	
	.select {

		display: inline-block;
		width: auto;
		/*margin: 5px 20px 20px 0;*/
		padding: 8px 30px 8px 10px;
		background-color: transparent;
		font-size: 12px;
	}


	.bottom-footer {

		padding: 30px;
		background-color: $primaryLight;
		text-align: center;
		border-top: 1px solid $thirdLight;

		.link {

			display: block;
			padding: 5px 15px;
			color: $thirdDark;
			font-size: 12px;
			font-family: $oxygen;
			font-weight: 400;
			line-height: 1.4em;
			text-decoration: none;
			transition: color ease-out .2s;

		    @media screen and (min-width: $smallBreakpoint) {
		        
		        display: inline-block;
		    }
		
			&:hover {
			
				color: $primaryColored;
			}
		}

		span.link {

			&:hover {

				color: $thirdDark;
			}
		}
	}

	.social-link {

		display: inline-block;
		width: 13px;
		height: 13px;
		margin: 0 2px 15px;
		padding: 8px 8px;
		background-color: $thirdDark;
		border: 2px solid $thirdDark;
		border-radius: 50px;
		color: $primaryLight;
		font-size: .9em;
		text-align: center;
		transition: all ease-out .2s;


		&:hover {
		
			background-color: $primaryLight;
			color: $thirdDark;
		}
	}
}