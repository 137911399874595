.campaign-contributions {
    display: none;

    @media screen and (min-width: $mediumBreakpoint) {
        display: block;
    }

    &.for-mobile {
        display: block;
        margin-top: 40px;

        @media screen and (min-width: $mediumBreakpoint) {
            display: none;
        }
    }

    .contributions {

        width: 100%;
        margin-top: 20px;
        font-family: $oxygen;
        border-collapse: collapse;

        @media screen and (min-width: $smallBreakpoint) {
            margin-top: 40px;
        }

        @media screen and (max-width: $smallBreakpoint) {

            padding-right: 0;
        }

        @media screen and (max-width: $tinyBreakpoint) {

            padding-right: 0;
        }

        tr {

            height: 90px;

            th, td {

                border-bottom: 1px solid $thirdLight;
            }
        }

        .rank {

            font-size: 16px;

            @media screen and (max-width: $tinyBreakpoint) {

                width: 20px;
            }
        }

        .image {

            width: 80px;
            text-align: center;

            @media screen and (max-width: $tinyBreakpoint) {

                width: 40px;

                .avatar {

                    width: 25px;
                    height: 25px;
                }
            }

            .avatar {

                width: 50px;
                height: 50px;
                margin: 0;
            }
        }

        .user {

            @media screen and (max-width: $tinyBreakpoint) {

                width: 130px;
                padding-left: 5px;

                .reading-text {

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 130px;
                }
            }

            .reading-text {

                margin: 0;
            }

            .from {

                font-size: 12px;
            }
        }

        .price {

            text-align: right;
            font-size: 22px;
            color: $primaryColored;
        }
    }

    .more-contributions {

        width: calc(100% - 40px);
        margin-top: 25px;
        font-size: 3em;
        font-weight: 300;
        line-height: .5em;
        padding: 7px;

        @media screen and (max-width: $smallBreakpoint) {

            width: 100%;
        }
    }
}

.campaign-popular {

    float: left;
    width: calc(33.33334% - 1px);
    border-left: 1px solid $thirdLight;
    padding-left: 40px;
    box-sizing: border-box;

    @media screen and (max-width: $smallBreakpoint) {

        width: 100%;
        padding-left: 0;
        border: none;
    }

    @media screen and (max-width: $tinyBreakpoint) {

        margin-top: 35px;
    }

    .package {

        margin-top: 40px;

        @media screen and (max-width: $smallBreakpoint) {

            float: left;
            width: 47.5%;

            &:last-child {
                margin-left: 5%;
            }
        }

        @media screen and (max-width: $tinyBreakpoint) {

            width: 100%;
            margin-top: 0;

            &:last-child {
                margin-left: 0;
            }
        }
    }
}