.tag {

    display: inline-block;

    margin: 0 10px 0 0;
    padding: 5px 8px;

    border: 1px solid $borderGray;
    border-radius: 3px;

    transition: opacity ease-out .2s;
    font-family: $quicksand;
    color: $borderGray;
    font-size: 10px;
    text-decoration: none;
    font-weight: 700;
    transition: opacity ease-out .2s;
    text-transform: uppercase;

    .fa {

        margin-right: 5px;
        color: $thirdDark;

        &.fa-map-marker:before {

            font-family: BeanIcons;
             content: '\e906';
        }

        &.fa-tag:before {

            font-family: BeanIcons;
            content: '\e90a';
        }
    }


    &:hover {

        opacity: .75;
    }

    &.small {

        text-overflow: ellipsis;
        max-width: 140px;
        white-space: nowrap;
        overflow: hidden;
    }

}