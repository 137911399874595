
.explanation_group {

    width: 100%;

    .explanation {

        width: 33.33334%;
        padding: 0 10px;
        box-sizing: border-box;
        float: left;
        text-align: center;

        .image {

            width: 150px;
            height: 150px;
            margin: 0 auto 30px;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;

            &.talk {

                background-image: url('../images/about_talk.svg');
            }

            &.share {

                background-image: url('../images/about_share.svg');
            }

            &.smile {

                background-image: url('../images/about_smile.svg');
            }
        }

        .reading-text {

            margin: 0;

            &.title {

                font-weight: bold;
                margin-bottom: 10px;
            }
        }

        @media screen and (max-width: $smallBreakpoint) {

            width: 100%;
            margin: 20px 0 0 0;
        }
    }
}

.screenshot {

    width: calc(100% - 2px);
    margin: 30px 0 60px;

    @media screen and (max-width: $tinyBreakpoint) {

    margin: 30px 0;
    }
}

.green-section {

    background-color: $primaryColored;
    color: $primaryLight;

    .wrapper {

        margin: 0 auto;
    }

    .title-1, .title-3 {

        color: $primaryLight;
    }

    .responsive {

        margin: 60px auto 80px;

        @media screen and (max-width: 835px) {

            width: 100%;
            margin: 20px 0;
        }
    }
}

.home-full.footer.about {

    background-image: url('../images/about_footer.jpg');
    padding: 60px 0;
    border: none;

    .title-2 {

        color: $primaryLight;
        margin-bottom: 0;
    }

    @media screen and (max-width: $smallBreakpoint) {

        padding: 20px;
    }
}

@media screen and (max-width: $smallBreakpoint) {

    .button-bordered.about {

        width: 100%;
        margin: 20px 0 0;
    }
}