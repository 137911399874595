
.alert {

	margin: 0 auto 50px;
	padding: 12px 20px;
	background-color: $primaryDark;
	color: $primaryLight;
	font-size: 16px;
	font-family: $oxygen;
	font-weight: 500;
	text-align: center;

	&.success { 

		background-color: $primaryColored; 
		color: $primaryLight; 
	}

	&.warning { 

		background-color: $warningColor; 
		color: $primaryLight; 
	}

	&.error { 

		background-color: $errorColor; 
		color: $primaryLight; 
	}


	.icon {

		font-size: 1.6em;
		vertical-align: middle;
		padding-bottom: 5px;
		padding-right: 10px;

		&.check {

			&:after {

				content: "\e901";
				font: normal normal normal 16px/1 BeanIcons;
			}
		}

		&.close {

			&:after {

			content: "\e902";
			font: normal normal normal 16px/1 BeanIcons;
			}
		}
	}

	&.home {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 10px 0;
	}
}