
.block-info {

    font-family: $oxygen;
    display: inline-block;

    .price {

    margin: 0;
    font-size: 22px;
    color: $primaryColored;

        sup {

            font-weight: 700;
        }
    }

    .reading-text {

        margin: 5px 0;
    }
}