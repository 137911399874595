

::-webkit-input-placeholder { color: $secondaryDark; }

:-moz-placeholder { color: $secondaryDark; }

::-moz-placeholder { color: $secondaryDark; }

:-ms-input-placeholder { color: $secondaryDark; }

::-ms-input-placeholder { color: $secondaryDark; }

:placeholder-shown { color: $secondaryDark; }

:placeholder { color: $secondaryDark; }


* {

	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


body {
	
	margin: 0;
	padding: 0;
}




input:focus,
select:focus,
textarea:focus,
button:focus {

    outline: none;
	-webkit-appearance: none;
}

.clearfix {

	clear: both;
}

@font-face {
	font-family: 'BeanIcons'; /*a name to be used later*/
	src: url('../../beanicons.ttf'); /*URL to font*/
}