.package {

    width: 100%;
    position: relative;
    padding-top: 40px;
    margin-bottom: 40px;

    .rounded {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: -1;

        &.brown .name {

            background: $brownCoffee;
        }

        &.pink .name {

            background: $pinkCoffee;
        }

        &.yellow .name {

            background: $yellowCoffee;
        }

        &.green .name {

            background: $primaryColored;
        }

        .name {

            margin: 0 auto;
            width: 200px;
            height: 200px;
            border-radius: 200px;
            color: white;
            text-align: center;
            line-height: 50px;
            font-family: $oxygen;
            font-weight: 400;
            font-size: 16px;
        }
    }

    .container {

        padding: 20px;
        box-sizing: border-box;
        border: 1px solid $thirdLight;
        overflow: hidden;
        position: relative;
        background: $primaryLight;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;

        .image {

            position: absolute;
            margin: 0 auto;
            left: 0;
            top: -41px;
            width: 100%;
            text-align: center;

            img {

                width: 200px;
            }
        }

        .price {

            position: absolute;
            top: 80px;
            right: 5%;
            padding: 12px 10px 10px;
            border-radius: 75px;
            width: 75px;
            height: 75px;
            box-sizing: border-box;
            border: 1px solid $thirdLight;
            text-align: center;
            background: $primaryLight;
            color: $primaryColored;
            font-family: $oxygen;
            font-weight: 700;
            font-size: 1.1em;
            opacity: .9;

            .old_percent {

                text-decoration: line-through;
                font-size: .7em;
                color: $borderGray;
            }

            .currency {

                font-size: .7em;
                margin-top: -4px;
            }
        }

        .title-3 {

            margin: 160px 0;
        }

        .reading-text {

            height: 130px;
            overflow: hidden;

            &.participate {

                margin-top: 0;
                height: auto;
                padding: 10px;
                font-size: 18px;
                text-align: center;

                span {

                    font-weight: bold;
                }

                &.brown span {

                    color: $brownCoffee;
                }

                &.pink span {

                    color: $pinkCoffee;
                }

                &.yellow span {

                    color: $yellowCoffee;
                }

                &.green span {

                     color: $primaryColored;
                 }
            }

            &.shipping {

                height: 25px;
                margin-bottom: 0;
             }

            &.title {

                margin-top: 160px;
                font-weight: bold;
                height: auto;

                &.green {

                    color: $primaryColored;
                    margin-top: 0;
                    font-size: 14px;
                    max-height: 25px;
                }
            }

            &.title-2 {

                margin-top: 0;
                font-weight: bold;
                height: auto;
            }

            &.description {

                font-size: 14px;
             }
        }

        .button {

            margin: 0;
            width: 100%;
            text-align: center;
            padding: 10px;
        }
    }
}