
/*
 * 		Colors
 * 		------
 */


$primaryDark	 	: #50443F;
$secondaryDark		: #b6afa8;
$thirdDark			: #211e3f;
$primaryLight		: white;
$secondaryLight		: #f9f9f9;
$thirdLight			: #e4e2e0;
$primaryColored		: #61bf8a;

$borderGray         : #726f6c;
$searchColor        : #2B2725;
$optional           : #b9b9b9;

$facebookColor		: #3b5998;
$twitterColor		: #55acee;

$warningColor		: #e9931b;
$errorColor			: #e63339;

$brownCoffee        : #50443F;
$pinkCoffee         : #F2397E;
$yellowCoffee       : #FD9D25;


/*
 * 		Fonts
 * 		-----
 */


$primaryFont 	 		: 'Roboto', 'Arial';
$secondaryFont 	 		: 'Helvetica', 'Verdana';
$oxygen                 : 'Oxygen', 'Helvetica', Arial;
$quicksand              : 'Quicksand', 'Helvetica', Arial;




/*
 * 		Breakpoints
 * 		-----------
 */


 $tinyBreakpoint 		: 480px;
 $smallBreakpoint 		: 768px;
 $mediumBreakpoint 		: 1024px;
 $largeBreakpoint 		: 1360px;
 $hugeBreakpoint 		: 1600px;

