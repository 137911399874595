.illustration {

    position: relative;

    &.video:hover {

        .remaining, .progress-bar {

            display: none;
        }
    }

    .image {

        width: 100%;
        height: auto;
    }

    .video {

        width: 100%;
        height: 492px;

        @media screen and (max-width: $mediumBreakpoint) {

            height: 547px;
        }

        @media screen and (max-width: $smallBreakpoint) {

            height: 331px;
        }

        @media screen and (max-width: $tinyBreakpoint) {

            height: 270px;
        }
    }

    .overflow {

        content: '';
        position: absolute;
        top: 0;
        bottom: 4px;
        left: 0;
        right: 0;
        background: rgba(12,12,12,0.2);
        background: -moz-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.7) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(12,12,12,0.2)), color-stop(100%, rgba(28,28,28,0.7)));
        background: -webkit-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.7) 100%);
        background: -o-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.7) 100%);
        background: -ms-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.7) 100%);
        background: linear-gradient(to bottom, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.7) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c0c0c', endColorstr='#1c1c1c', GradientType=0 );
    }

    .remaining {

        position: absolute;
        top: 10px;
        right: 10px;
        text-align: center;

        .day {

            width: 10px;
            display: inline-block;
            padding: 5px;
            background: $primaryColored;
            font-family: 'Oxygen', 'Helvetica', Arial;
            color: $primaryLight;
            font-size: 1em;
            font-weight: 600;
            margin: 0 -1px;

            &:first-child {
                 border-top-left-radius: 3px;
                 border-bottom-left-radius: 3px;
            }

            &:nth-last-child(2) {
                 border-top-right-radius: 3px;
                 border-bottom-right-radius: 3px;
            }
        }

        &.soon .day {
             background: $warningColor;
        }

        &.soon .day {
             background: $warningColor;
        }

        &.ending .day {
             background: $errorColor;
        }

        .reading-text {

            margin: 0;
            color: white;
            font-family: 'Oxygen', 'Helvetica', Arial;
        }
    }

    .progress-bar {

        position: absolute;
        bottom: 20px;
    }
}