

.campaign {

	.wrapper {

		width: 100%;
		max-width: 1024px;
		margin: 0 auto;
		box-sizing: border-box;
		text-align: center;
		clear: both;

		@media screen and (max-width: $smallBreakpoint) {

			padding: 20px 20px 120px 20px;
		}

		&.new_campaign {

		 	.title-1.centered {

				margin-bottom: 10px;
			}

		 	.title-3.centered {

				margin-bottom: 60px;

				@media screen and (max-width: $tinyBreakpoint) {

					margin-bottom: 35px;
				}
			}

			.campaign-section {

				margin-top: 60px;

				@media screen and (max-width: $tinyBreakpoint) {

					margin-top: 25px;
				}
			}
		}
	}


	.section {

		padding-bottom: 60px;
	}


	.actions-bar {

		position: fixed;
		-webkit-overflow-scrolling: touch;
		transform: translateZ(0);
		bottom: 0;
		left: 0;
		right: 0;
		padding: 20px;
		box-sizing: border-box;
		background-color: $thirdDark;
		text-align: center;

		@media screen and (max-width: $tinyBreakpoint) {

			padding: 10px 20px;

			.button, .button-bordered {

				font-size: 12px !important;
				padding: 7px 10px !important;
			}
		}

		.button,
		.button-bordered {

			width: 100%;
			margin: 5px 0;

		    @media screen and (min-width: $smallBreakpoint) {

				width: auto;
				margin: 0 10px;
		    }
		}
	}

	.radios-block {

		.radio {

			padding: 20px 2.5px 30px;
			margin: 0;

			@media screen and (max-width: $tinyBreakpoint) {

				width: 50%;
				float: left;
				padding: 30px 0;
				text-align: center;
			}

			span {

				padding: 15px 10px;

				@media screen and (max-width: $tinyBreakpoint) {

					padding: 15px 25px;
					font-size: 12px;
				}
			}

			.recommand {

				bottom: -5px;
			}
		}
		
		.error-message {

			padding: 20px 0 0 10px;
			clear: left;
		}
	}


	.delete-picture,
	.delete-video {

		display: none;
	}


	.hasPicture,
	.hasVideo {

		.preview {

			border: 2px solid $thirdLight;
		}

		.delete-picture {

			display: inline-block;
		}

		.delete-video {

			display: inline-block;
		}

		.input-file,
		.video-field {

			display: none;
		}

		.infos {

			display: none;
		}
	}

	.mce-tinymce {

		margin: 15px 0 5px;
	}


	.invitation-block {

		max-width: 600px;

		.input-group { 

			max-width: none; 
		}

		@media screen and (max-width: $tinyBreakpoint) {

			.input-block {

				margin-top: 0;
			}
		}
	}

}