
.order-table {

    margin-bottom: 20px;
    color: $primaryDark;
    font-family: $oxygen;

    .col-1 {

        float: left;
        width: 20%;
    }

    .col-4 {

        float: left;
        width: 80%;
    }

    .col-offset-3 {

        margin-left: 60%;
    }

    .text-right {

        text-align: right;
    }

    .price {

        font-size: 16px;
        font-weight: 500;

        &.donation {
            padding: 10px 0 5px !important;

            .select-group {
                float: right;
                padding-bottom: 0;
            }
        }

        &.total {

            font-size: 22px;
            font-weight: 500;
            color: $primaryColored;
        }
    }

    .order-label {

        font-size: 14px;
        font-weight: 400;

        &.total {

            padding-top: 16px;
        }
    }

    .order-header {

        font-weight: 500;
        font-family: $quicksand;
        font-size: 18px;
        color: $primaryDark;
        padding: 20px 0;
        border-bottom: 1px solid $thirdLight;

        @media screen and (max-width: $tinyBreakpoint) {

            .text-right {

                display: none;
            }

            .col-4 {

                width: 100%;
            }
        }
    }

    .order-footer {

        padding: 15px 0;
        border-top: 1px solid $thirdLight;

        .price {

            padding: 5px 0;

            @media screen and (max-width: $smallBreakpoint) {

                width: 20%;
            }

            @media screen and (max-width: $tinyBreakpoint) {

                width: 40%;
            }
        }
        .order-label {

            padding: 10px 0 5px;

            @media screen and (max-width: $smallBreakpoint) {

                width: 25%;
                margin-left: 55%;
            }

            @media screen and (max-width: $tinyBreakpoint) {

                width: 60%;
                margin-left: 0;
            }
        }
    }

    .order-body {

        padding: 30px 0;

        @media screen and (max-width: $tinyBreakpoint) {

            padding: 30px 0 0;

            .price {

                display: none;
            }
        }
    }

    .product {

        @media screen and (max-width: $tinyBreakpoint) {

            width: 100%;
            width: 100%;
            text-align: center;
        }

        .image {

            float: left;
            width: 160px;

            @media screen and (max-width: $tinyBreakpoint) {

                float: initial;
            }
        }

        .product-infos {

            float: left;
            padding-left: 60px;
            box-sizing: border-box;
            width: calc( 100% - 160px );

            @media screen and (max-width: $smallBreakpoint) {

                width: calc( 100% - 160px );
            }

            @media screen and (max-width: $tinyBreakpoint) {

                padding: 20px 0;
                float: initial;
                width: 100%;
                text-align: center;
            }

            .name {

                font-size: 16px;
                font-family: $oxygen;
                font-weight: 600;
                margin-bottom: 10px;

                span {
                    color: $primaryColored;
                }
            }

            .description {

                font-size: 16px;
                font-weight: 500;
                margin-bottom: 40px;
            }

            .order-index-options {
                -js-display: flex;
                display: flex;
                flex-direction: column;
            }

            .order-index-option {
                -js-display: flex;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 10px;

                label {
                    margin-right: 10px;
                    min-width: 125px;
                }

                .select-group {
                    margin: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}

.billing_choice {

    color: $primaryDark;
    font-family: $oxygen;
    font-size: 14px;
    padding: 30px 0;
}

.buttons {

    padding-top: 20px;

    .button-bordered {

        float: left;
    }

    .button {

        float: right;
    }

    .step {

        padding: 10px 40px 9px;
        margin: 15px 0;
        border-radius: 30px;
        border: solid 1px;
    }

    @media screen and (max-width: $smallBreakpoint) {

        .button, .button-bordered {

            margin: 15px 0 0 0;
        }
    }
}

#billing {

    display: none;
}

section {

    &.margin-top {

        display: block;
        margin-top: 30px;
    }

    &.margin-bottom {

        display: block;
        margin-bottom: 30px;
    }
}

.campaign .section {

    padding-bottom: 0;

    .title-2 {

        margin-top: 0;
    }
}

.wrapper.partials .section .input-edit-group .input-edit {

    margin-bottom: 20px;
}

.price.donation div {
    position: relative;

    &:after {
        content: '$';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: $primaryColored;
    }
}

#select_donation {
    width: 50%;
    text-align: right;
    padding-right: 20px;
}

#donation_amount {
    font-weight: bold;

    &:after {
        content: '$'
    }
}