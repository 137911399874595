
.swiper-container {

    .swiper-wrapper {

        display: block;

        @media screen and (max-width: $mediumBreakpoint) {

            display: flex;
        }

        .swiper-slide {

            float: left;
            width: 31.66667%;
            box-sizing: border-box;

            @media screen and (min-width: $mediumBreakpoint) {

                &:nth-child(3n+2) {

                    margin: 0 2.5%;
                }
            }
        }
    }
}