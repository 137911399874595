
.fade-container {

    transition: all ease-out .2s;
}

.coloredWrapper {

    background: $secondaryLight;
}

.wrapper.single {

    margin-bottom: 0;

    @media screen and (max-width: $mediumBreakpoint) {

        padding-bottom: 35px;
    }

    .title-1 {

        margin-bottom: 20px;
    }

    .presentation {

        margin-top: 60px;

        @media screen and (max-width: $tinyBreakpoint) {

            margin-top: 35px;
        }

        .illustration {

            float: left;
            width: 66.66667%;

            @media screen and (max-width: $smallBreakpoint) {

                width: 100%;
            }

            .remaining {

                top: 20px;
                right: 20px;

                @media screen and (max-width: $tinyBreakpoint) {

                    top: 10px;
                    right: 10px;
                }
            }

            .progress-bar {

                padding: 0 20px;

                @media screen and (max-width: $tinyBreakpoint) {

                    padding: 0 10px;
                }

                .progress-background {

                    height: 15px;
                    border-radius: 15px;

                    @media screen and (max-width: $tinyBreakpoint) {

                        height: 25px;
                        border-radius: 25px;
                    }

                    .progress-value {

                        height: 15px;

                        @media screen and (max-width: $tinyBreakpoint) {

                            height: 25px;
                        }
                    }
                }
            }
        }

        .informations {

            float: left;
            width: 33.3334%;
            padding-left: 40px;
            box-sizing: border-box;

            @media screen and (max-width: $smallBreakpoint) {

                width: 100%;
                padding-left: 0;
                padding-top: 20px;
            }

            .featured-light {

                height: 25px;
                line-height: 25px;
                color: $errorColor;
                font-size: 16px;
                font-family: $oxygen;
                margin-bottom: 20px;

                &:before {

                    content: "\e904";
                    font: normal normal normal 16px/1 BeanIcons;
                    padding-right: 10px;
                    vertical-align: baseline;
                }

                @media screen and (max-width: $smallBreakpoint) {

                    float: left;
                }
            }

            .infos {

                font-family: $oxygen;
                margin-bottom: 25px;

                /*@media screen and (max-width: $mediumBreakpoint) {*/

                    /*float: left;*/
                    /*margin-top: 20px;*/
                    /*width: 25%;*/
                /*}*/

                @media screen and (max-width: $smallBreakpoint) {

                    float: left;
                    width: 50%;
                }

                @media screen and (max-width: $tinyBreakpoint) {

                    margin-bottom: 10px;
                    width: 100%;
                }

                &:first-child {

                    /*@media screen and (max-width: $mediumBreakpoint) {*/

                        /*width: 50%;*/
                    /*}*/

                    @media screen and (max-width: $smallBreakpoint) {

                        width: 100%;
                    }
                }

                .number {

                    font-weight: 500;
                    font-size: 40px;

                    &.green {

                        color: #61bf8a;
                    }

                    sup {

                        font-size: 18px;
                    }
                }

                .reading-text {

                    margin: 0;
                    font-size: 16px;
                    font-weight: 400;
                }

                .progress-bar {

                    padding: 5px 0 0;

                    /*@media screen and (max-width: $mediumBreakpoint) {*/

                        /*padding: 5px 10px 0 0;*/
                    /*}*/

                    @media screen and (max-width: $smallBreakpoint) {

                        padding: 5px 0 0;
                    }

                    @media screen and (max-width: $tinyBreakpoint) {

                        .progress-background, .progress-value {

                            height: 10px;
                        }
                    }

                    .progress-background {

                        background-color: $thirdLight;
                    }
                }
            }


        }

        .buttons {

            padding-top: 20px;

            /*@media screen and (max-width: $mediumBreakpoint) {*/

                /*clear: both;*/
                /*text-align: center;*/
            /*}*/

            .button, .button-bordered {

                text-align: center;
                width: 100%;
                margin: 10px 0;

                /*@media screen and (max-width: $mediumBreakpoint) {*/

                    /*width: auto;*/
                    /*padding: 15px 40px;*/
                    /*margin: 0 5px;*/
                /*}*/

                @media screen and (max-width: $smallBreakpoint) {

                    width: auto;
                    margin: 20px 0 0 0;
                    width: 100%;
                }
            }

            .share {

                width: 100%;
                text-align: center;

                @media screen and (max-width: $smallBreakpoint) {

                    float: left;
                    padding-top: 10px;
                }

                .button {

                    float: left;
                    width: calc(50% - 5px);
                    padding: 7px 15px;
                    margin-top: 5px;

                    &:first-child {

                        margin-right: 10px;
                     }

                    element {

                        padding-right: 10px;

                        @media screen and (max-width: $tinyBreakpoint) {

                            display: none;
                        }
                    }

                    @media screen and (max-width: $tinyBreakpoint) {

                        font-size: 11px !important;

                        span {

                            padding-right: 0;
                        }
                    }
                }
            }

        }

    }

}

.tabs {

    background: $primaryLight;
    border-top: 1px solid $thirdLight;
    border-bottom: 1px solid $thirdLight;

    @media screen and (max-width: $smallBreakpoint) {

        width: 100%;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        transform: translateZ(0);

        .container {

            /*width: 620px;*/
            width: 680px;
            margin-left: 0;
        }
    }

    &.fixed {

         position: fixed;
         width: 100%;
         z-index: 2;
         -webkit-overflow-scrolling: touch;
         transform: translateZ(0);
    }

    .container {

        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;

        @media screen and (max-width: $mediumBreakpoint) {

            padding: 0 30px;
        }

        @media screen and (max-width: $tinyBreakpoint) {

            padding: 0 20px;
            width: 665px;

            .tab {

                font-size: 16px;

                &:last-child {

                    margin-right: 0;
                }
            }
        }

        .tab {

            display: inline-block;
            text-transform: uppercase;
            color: $primaryDark;
            text-decoration: none;
            font-family: $quicksand;
            margin-right: 60px;
            padding: 25px 0;
            font-size: 12px;
            font-weight: 700;
            cursor: pointer;
            transition: all ease-out .2s;

            &:hover, &.active {

                color: $primaryColored;
            }

            @media screen and (max-width: $smallBreakpoint) {

                margin-right: 40px;
            }

            @media screen and (max-width: $tinyBreakpoint) {

                margin-right: 40px;
                padding: 20px 0;
            }
        }
    }
}

.wrapper.partials {

    margin: 0 auto;
    padding: 60px 20px;

    @media screen and (max-width: $mediumBreakpoint) {

        padding: 35px 30px;
    }

    @media screen and (max-width: $tinyBreakpoint) {

        padding: 35px 20px;

        .title-1, .title-2, .title-3, .reading-text {

            text-align: center;
        }

        .campaign-description .reading-text {

            text-align: left;
        }
    }

    .title-3 {

        margin-bottom: 30px;
    }

    .package .title-3 {

        margin-bottom: 10px;
    }
}

.background-section {

    background: url('../images/home_dark.jpg') repeat center center;

    &.fixed {

        position: fixed;
        width: 100%;
        z-index: 2;
        -webkit-overflow-scrolling: touch;
         transform: translateZ(0);

        .wrapper.member {

            padding: 20px;

            .avatar {

                width: 50px;
                height: 50px;
            }

            .member_infos {

                .amount {

                    position: absolute;
                    right: 20px;
                    line-height: 50px;

                    @media screen and (max-width: $mediumBreakpoint) {

                        line-height: 50px;

                    }
                }

                .title-3 {

                    line-height: 50px;
                    margin: 0;

                    @media screen and (max-width: $mediumBreakpoint) {

                        margin: 0;
                        line-height: 50px;

                    }
                }

                .reading-text {

                    display: none;
                }

            }
        }
    }

    .wrapper.member {

        position: relative;
        margin: 0 auto;
        padding: 80px 20px;
        transition: padding ease-out .2s;

        @media screen and (max-width: $mediumBreakpoint) {

            padding: 20px;

            .avatar {

                width: 50px;
                height: 50px;
            }

            .member_infos {

                padding-left: 10px;

                .amount {

                    padding-left: 10px;
                    line-height: 20px;
                    font-size: 20px;
                }

                .title-3 {

                    padding-left: 10px;
                    margin: 0;
                    line-height: 20px;
                    margin-top: 10px;
                }

                .reading-text {

                    display: none;
                }

            }
        }

        .avatar {

            float: left;
            margin: 0;
        }

        .member_infos {

            float: left;
            padding-left: 60px;

            @media screen and (max-width: $tinyBreakpoint) {

                width: calc(100% - 60px);
            }

            .amount {

                color: $primaryColored;
                font-family: $oxygen;
                font-size: 40px;
                font-weight: 500;

                sup {

                    font-size: 18px;
                }
            }

            .title-3 {

                color: white;
                font-size: 22px;
                margin-bottom: 10px;

                @media screen and (max-width: $tinyBreakpoint) {

                    line-height: 25px;
                }
            }

            .reading-text {

                color: white;
                font-size: 16px;
                margin-top: 10px;
            }
        }
    }
}

.campaign-preview {

    &.draft {

         background-color: $thirdDark;
    }

    &.pending {

         background-color: #f0ad4e;
     }

    &.ended {

         background-color: $primaryDark;
     }

    &.fixed {

        position: fixed;
        width: 100%;
        z-index: 2;
         -webkit-overflow-scrolling: touch;
         transform: translateZ(0);
    }

    .wrapper {

        padding: 20px;
        margin: 0 auto;

        .text {

            float: left;
            font-family: $quicksand;
            font-size: 18px;
            color: $primaryLight;
            font-weight: 700;
            line-height: 35px;

            @media screen and (max-width: $tinyBreakpoint) {

                float: initial;
                width: 100%;
                text-align: center;
                line-height: inherit;
            }
        }

        .preview-buttons {

            float: right;

            @media screen and (max-width: $tinyBreakpoint) {

                float: initial;
                width: 100%;
                text-align: center;
                margin-top: 10px;
            }

            .button, .button-bordered {

                margin: 0 0 0 5px;

                @media screen and (max-width: $tinyBreakpoint) {

                    margin: 5px 0;
                }
            }
        }
    }
}