.progress-bar {

    position: relative;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    text-align: right;
    font-size: 1.4em;
    font-weight: 600;
    color: white;
    font-family: 'Oxygen', 'Helvetica', Arial;

    small {

        vertical-align: super;
        font-size: 0.6em;
    }

    .progress-background {

        width: 100%;
        height: 10px;
        background: $primaryLight;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 5px;
    }

    .progress-value {

        height: 10px;
        background: $primaryColored;
    }

    @media screen and (max-width: $tinyBreakpoint) {

        .percent {

            position: absolute;
            top: 6px;
            right: 20px;
            font-size: .8em;
            z-index: 1;
        }

        .progress-background {

            height: 25px;
            border-radius: 25px;
            background: rgba(255,255,255,.5);
        }

        .progress-value {

            height: 25px;
        }
    }
}