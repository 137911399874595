

.new-campaign {

	.wrapper {

		width: 100%;
		max-width: 520px;
		margin: 20px auto;
		padding: 20px;
		box-sizing: border-box;
		text-align: center;

		@media screen and (max-width: $tinyBreakpoint) {

			margin: 0;
			padding: 35px 20px;
		}

		.checkbox {

			padding: 10px 0;
			font-family: $oxygen;


		}
	}

	.form {

		margin-top: 40px;
	}

	.button {

		width: 100%;
	}

	.dollar {

		top: 15px;
	}


	@media screen and (max-width: $tinyBreakpoint) {

		.input-group.three-column {

			width: 70%;
		}

		.select-group.one-column {

			width: calc(30% - 20px);
		}
	}
}

.campaign.new .container .wrapper {

	@media screen and (max-width: $tinyBreakpoint) {

		.input-group.three-column {

			width: 65%;
		}

		.select-group.one-column {

			width: calc(35% - 20px);
		}
	}
}

#delete_block {

	display:none;
}