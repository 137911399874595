
.listing-link {

    text-decoration: none;
    color: $primaryDark;
}

.listing-campaign {

    width: 100%;
    margin-bottom: 30px;
    border: 1px solid $thirdLight;
    background-color: $primaryLight;
    box-sizing: border-box;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .illustration {

        transition: opacity ease-out .2s;

        .image {

            width: 100%;
        }

        .featured {

            position: absolute;
            top: 10px;
            left: 10px;
            background: $errorColor;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 36px;

            &:after {

                content: "\e904";
                font: normal normal normal 16px/1 BeanIcons;
                color: $primaryLight;
            }
        }
    }

    &:hover .illustration {

        opacity: .9;
    }

    .informations {

        padding: 20px;

        .title-3 {

            height: 50px;
            overflow: hidden;
        }

        @media screen and (max-width: $tinyBreakpoint) {

            .title-2 {

                clear: both;
                padding-top: 15px;
            }
        }

        .amount {

            font-family: $oxygen;

            .price {

                margin-top: 20px;
                font-weight: 300;
                font-size: 1.2em;
                color: $primaryColored;
            }

            .reading-text {

                margin: 0;
                font-family: $oxygen;
                font-weight: 300;
            }
        }

        .tags {

            margin: 20px 0 -5px;
            min-height: 33px;
        }

        .button, .button-bordered {

            margin-right: 10px;
            width: auto;
            margin-bottom: 0;
            text-align: center;

            @media screen and (max-width: $mediumBreakpoint) {

                padding: 10px 30px;
            }
        }
    }

    &.big {

        width: 100%;
        margin: 0;
        border: none;

        .illustration {

            float: left;

            .image {

                width: 400px;
            }

            @media screen and (max-width: $smallBreakpoint) {

                .image {

                    width: 300px;
                }
            }

            @media screen and (max-width: $tinyBreakpoint) {

                width: 100%;

                .image {

                    width: 100%;
                }
            }
        }

        .informations {

            padding: 0 0 0 430px;

            @media screen and (max-width: $smallBreakpoint) {

                padding: 0 0 0 330px;
            }

            @media screen and (max-width: $tinyBreakpoint) {

                padding-left: 0;
            }

            .title-2 {

                margin-bottom: 15px;
            }

            .description {

                display: block;
            }

            .amount .reading-text {

                margin: 5px 0;
            }
        }

        .button, .button-bordered {

            margin-right: 10px;
            width: auto;

            @media screen and (max-width: $smallBreakpoint) {

                width: 100%;
            }
        }
    }
}