
.campaign.summary .wrapper {

    padding-bottom: 100px;

    /*@media screen and (max-width: $smallBreakpoint) {*/

        /*padding-bottom: 160px;*/
    /*}*/
}

.campaign.summary .campaign-section {

    @media screen and (max-width: $tinyBreakpoint) {

        padding: 0;
    }
}


.campaign-section {

    padding-bottom: 25px;
    text-align: left;

    .title-2 {

        @media screen and (max-width: $tinyBreakpoint) {

            text-align: center;
        }

        .button-bordered {

            float: right;
            margin: 0;
            padding: 10px 40px;
            font-size: 12px;
            line-height: normal;

            @media screen and (max-width: $tinyBreakpoint) {

                margin: 20px 0;
            }

        }
    }

    .input-edit {

        @media screen and (min-width: $smallBreakpoint) {

            max-width: 100%;
        }
    }

    .campaign-half {

        float: left;
        width: 49%;
        margin-bottom: 20px;

        &:first-child {

            margin-right: 2%;
        }

        .image.preview {

            .overflow {

                content: '';
                position: absolute;
                top: 0;
                bottom: 4px;
                left: 0;
                right: 0;
                background: rgba(12,12,12,0.2);
                background: -moz-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.55) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(12,12,12,0.2)), color-stop(100%, rgba(28,28,28,0.55)));
                background: -webkit-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.55) 100%);
                background: -o-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.55) 100%);
                background: -ms-linear-gradient(top, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.55) 100%);
                background: linear-gradient(to bottom, rgba(12,12,12,0.2) 0%, rgba(28,28,28,0.55) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c0c0c', endColorstr='#1c1c1c', GradientType=0 );
            }
        }

        @media screen and (max-width: $smallBreakpoint) {

            margin: 0 0 20px 0;
            width: 100%;
        }

        .preview.video {

            margin-top: 10px;
        }
    }

    .description {

        padding: 15px;
        margin: 10px 0 20px;
        border: 1px solid $secondaryDark;
        border-radius: 3px;
        background: white;
        font-size: 1.2em;
        max-height: 300px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        @media screen and (max-width: $tinyBreakpoint) {

            max-height: 200px;
        }

        p:first-child {

            margin-top: 0;
        }

        h2 {

            font-family : $quicksand;
            font-weight: 400;
            font-size: 22px;
            margin-bottom: 30px;
        }

        h3 {

            font-family : $quicksand;
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 20px;
        }

        a {

            color: $primaryColored;
            text-decoration: none;
        }
    }

    .invitation {

        padding: 25px 20px;
        border-color: $secondaryDark;

        @media screen and (max-width: $tinyBreakpoint) {

            padding: 20px;
        }

        .reading-text {

            margin: 0 0 10px;
            font-size: 1.2em;

            @media screen and (max-width: $smallBreakpoint) {

                width: 85%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @media screen and (max-width: $tinyBreakpoint) {

                font-size: 1em;
            }
        }
        .green {

            text-transform: uppercase;
            font-size: .8em;
            font-family: 'Quicksand', 'Helvetica', Arial;
            font-weight: 600;
        }
        .campaign-close {

            position: absolute;
            right: 25px;
            top: 41.5px;
            display: block;

            @media screen and (max-width: $tinyBreakpoint) {

                top: 34.5px;
            }
        }
    }

}

.clearfix {

    clear: both;
}