

.button {
	
	display: inline-block;
	width: 100%;
	margin: 15px 0;
	padding: 16px 40px 14px;
	border: 1px solid $primaryDark;
	border-radius: 70px;
	box-sizing: border-box;
	background-color: transparent;
	color: $primaryDark;
	font-size: 16px;
	font-family: $quicksand;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	transition: all ease-out .2s;
	-webkit-appearance: none;


    @media screen and (max-width: $smallBreakpoint) {
        
        width: auto;
        padding: 16px 20px 14px;
    }


	&.small {

		padding: 10px 40px 9px;
		font-size: 12px;
		width: auto;


		@media screen and (max-width: $tinyBreakpoint) {

			width: 100%;
			text-align: center;
		}
	}


	&.fluid {

		width: 100% !important;
		text-align: center;
	}


	&.light {

		border-color: $primaryLight;
		background-color: $primaryLight;
		color: $primaryDark;	
	}


	&.margin {

		margin: 35px 0 15px;
	}


	&.green {

		border-color: $primaryColored;
		background-color: $primaryColored;
		color: $primaryLight;

		&:hover {

			border-color: #56A97A;
			background-color: #56A97A;

			&.disabled {

				border-color: $primaryColored;
				background-color: $primaryColored;
				color: $primaryLight;
			}
		}
	}


	&.red {

		border-color: $errorColor;
		background-color: $errorColor;
		color: $primaryLight;

		&:hover {

			border-color: $errorColor;
			background-color: $primaryLight;
			color: $errorColor;
		}
	}


	&.facebook {
		
		border-color: $facebookColor;
		background-color: $facebookColor;
		color: $primaryLight;

		&:hover {

			background-color: $primaryLight;
			color: $facebookColor;
		}
	}


	&.twitter {

		border-color: $twitterColor;
		background-color: $twitterColor;
		color: $primaryLight;

		&:hover {

			background-color: $primaryLight;
			color: $twitterColor;
		}
	}


	&.icon {
		
		padding: 13px 40px 10px;

		span {

			display: inline-block;
			padding-right: 15px;
			font-size: 1.6em;
			vertical-align: sub;
		}
	}

	&:disabled, &.disabled {

		opacity: .5;
		cursor: default;
	}
}


.button-bordered {

	display: inline-block;
	margin: 15px 0;
	padding: 16px 40px 14px;
	border: 1px solid $primaryDark;
	border-radius: 70px;
	box-sizing: border-box;
	background-color: transparent;
	color: $primaryDark;
	font-size: 16px;
	font-family: $quicksand;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	transition: all ease-out .2s;
	-webkit-appearance: none;


	@media screen and (max-width: $smallBreakpoint) {

		width: auto;
		padding: 16px 20px 14px;
	}


	&.small {

		padding: 10px 40px 9px;
		font-size: 12px;
		width: auto;


		@media screen and (max-width: $smallBreakpoint) {

			padding: 10px 20px 9px;
		}


		@media screen and (max-width: $tinyBreakpoint) {

			width: 100%;
			text-align: center;
		}
	}


	&.fluid {

		width: 100% !important;
		text-align: center;
	}


	&.light {

		border-color: $primaryLight;
		color: $primaryLight;

		 &:hover {

			border-color: $primaryLight;
			background-color: $primaryLight;
			color: $primaryColored;
		}
	}


	&.margin {

		margin: 35px 0 15px;
	}


	&.green {

		border-color: $primaryColored;
		color: $primaryColored;

		&:hover {

			border-color: $primaryColored;
			background-color: $primaryColored;
			color: $primaryLight;
		}
	}


	&.red {

		border-color: $errorColor;
		color: $errorColor;

		&:hover {

			border-color: $errorColor;
			background-color: $errorColor;
			color: $primaryLight;
		}
	}


	&.facebook {

		border-color: $facebookColor;
		color: $facebookColor;
	}
}

@media screen and (max-width: $tinyBreakpoint) {

	.button, .button-bordered {

		padding: 10px !important;
		font-size: 16px !important;

		&.small {
			font-size: 12px !important;
		}
	}
}