
.page_404 {

    text-align: center;
    padding: 0 20px;

    .image_404 {

        margin-top: 100px;
    }

    .title-1 {

        margin: 60px 0 10px 0;
    }

    .button.green {

        margin-bottom: 100px;
    }

    @media screen and (max-width: $tinyBreakpoint) {

        .image_404 {

            margin-top: 50px;
        }

        .button.green {

            margin-bottom: 50px;
        }
    }
}